import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL

import authHeader from '/src/services/auth.header';

class ProductService {
    get(id, params = {}) {
        return axios.get(
            `${API_URL}product/${id}`,
            {headers: authHeader(), params: params})
    }

    list(params) {
        return axios.get(`${API_URL}product/`, {
            headers: authHeader(),
            params: params
        })
    }
    patch(id, data = {}) {
      console.log(id, data)
      return axios.patch(
        `${API_URL}product/${id}/`,
        data,
        {headers: authHeader()}
      )
    }

    put(id, data = {}) {
      console.log(id, data)
        return axios.put(
            `${API_URL}product/${id}/`,
            data,
            {headers: authHeader()}
        )
    }

    post(data = {}) {
        return axios.post(
            `${API_URL}product/`,
            data,
            {headers: authHeader()}
        )
    }

    delete(id) {
        return axios.delete(
            `${API_URL}product/${id}/`,
            {headers: authHeader()}
        )
    }
    clone(product) {
    return axios.post(`${API_URL}product/${product.id}/clone/`, product, { headers: authHeader()})
    }
    default_sub_activities() {
        return axios.get(
            `${API_URL}product/default_sub_activities/`,
            {headers: authHeader()}
        )
    }

    status_choices() {
        return axios.get(
            `${API_URL}product/status_choices/`,
            {headers: authHeader()}
        )
    }

    saveNoteAttachment(id, file) {
        let data = new FormData();
        data.append('file', file);
        return axios.patch(
            `${API_URL}product/${id}/save_note_attachment/`,
            data,
            {headers: {...authHeader(), ...{'content-type': 'multipart/form-data'}}}
        )
    }
}

export default new ProductService();
