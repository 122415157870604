import axios from 'axios';
import authHeader from '/src/services/auth.header';

const API_URL = process.env.VUE_APP_API_URL


class SubActivityService {
    get(id, params = {}) {
        return axios.get(
            `${API_URL}organization/${id}/`,
            {headers: authHeader(), params: params})
    }

    list(params) {
        return axios.get(`${API_URL}organization/`, {
            headers: authHeader(),
            params: params
        })
    }
}

export default new SubActivityService();
