import axios from 'axios';
const API_URL = process.env.VUE_APP_API_URL

import authHeader from '/src/services/auth.header';

class ProductKindService {
    get(id, params = {}) {
        return axios.get(
            `${API_URL}product_kind/${id}/`,
            {headers: authHeader(), params: params})
    }

    list(params) {
        return axios.get(`${API_URL}product_kind/`, {
            headers: authHeader(),
            params: params
        })
    }
}

export default new ProductKindService();