<template>
  <v-container
    v-if="activitiesMenu"
    id="scroll-target"
    style="max-height: 700px"
    class="overflow-y-auto"
  >
  <v-list v-for="activity in activitiesMenu" :key="activity.id" flat v-scroll:#scroll-target="onScroll" class="ma-lg-0">
    <v-card  class="blue-grey lighten-5">
        <v-card-title class="d-inline-flex">
          <div class="float-lg-left">
            {{ activity.name }}
          </div>
          <div class="float-right">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="$store.state.user.role === 'ADMIN'"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="cloneActivity(activity.id)"
                >
                  mdi-arrange-send-backward
                </v-icon>
              </template>
              <span>Clona Attività</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="$store.state.user.role === 'ADMIN'"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteActivity(activity)"
                >
                  mdi-trash-can-outline
                </v-icon>
              </template>
              <span>Elimina Attività</span>
            </v-tooltip>
          </div>
        </v-card-title>
        <v-list-item-group color="primary">
            <v-list-item
                v-for="product in activity.products" :key="product.id"
                :class="{ border: selectedProduct === product.id }"
            >
                <v-list-item-icon @click="editProduct(product.id, activity.id)">
                    <v-icon v-text="$icons.product"></v-icon>
                </v-list-item-icon>
                <v-list-item-content @click="editProduct(product.id, activity.id)">
                <v-list-item-title v-text="product.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action @click="cloneProduct(product)" >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="float-end"
                        v-if="$store.state.user.role === 'ADMIN'"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-arrange-send-backward
                      </v-icon>
                    </template>
                    <span>Clona Prodotto</span>
                  </v-tooltip>
                </v-list-item-action>
                <v-list-item-action @click="updateActivity(product)">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="float-end"
                        v-if="$store.state.user.role === 'ADMIN'"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-archive-plus-outline
                      </v-icon>
                    </template>
                    <span>Aggiorna Attività</span>
                  </v-tooltip>
                </v-list-item-action>
                <v-list-item-action @click="deleteProduct(product)">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="float-end"
                        v-if="$store.state.user.role === 'ADMIN'"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </template>
                    <span>Elimina Prodotto</span>
                  </v-tooltip>
              </v-list-item-action>
              </v-list-item>
            <v-col>
                <v-btn
                  :disabled="$store.state.user.role !== 'ADMIN'"
                  color="primary"
                  width="100%"
                  @click="createProduct(activity.id)">
                    Aggiungi prodotto per {{ activity.name }}
                </v-btn>
            </v-col>
        </v-list-item-group>
    </v-card>
  <v-dialog
    transition="dialog-top-transition"
    max-width="600"
    v-model="dialogCloneActivity"
  >
    <template v-slot:default="dialogCloneActivity">
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >Clona l'attività <span class="text-decoration-underline ml-1 mr-1"> {{ activity.name }} </span> nel progetto:
        </v-toolbar>
        <v-card-text>
          <div class="pa-12">
            <if65-autocomplete
              v-model="filterProject"
              :api="$projectService.list"
              :dense="true"
              :multiple="false"
              :flat="false"
              label="Scegli il progetto da aggiornare"/>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="dialogCloneActivity.value = false"
          >Close</v-btn>
          <v-btn
            text
            color="success"
            @click="saveCloneActivity"
          >Clona</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
  </v-list>
  <v-dialog
    transition="dialog-top-transition"
    max-width="600"
    v-model="dialog"
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >Aggiungi {{ product.name }} all'attività:
        </v-toolbar>
        <v-card-text>
          <div class="pa-12">
            <if65-autocomplete
              v-model="filters.activities"
              :api="$activityService.list"
              :dense="true"
              :multiple="true"
              :flat="false"
              label="Scegli l'attività da aggiornare"/>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="dialog.value = false"
          >Close</v-btn>
          <v-btn
            text
            color="success"
            @click="saveActivity"
          >Aggiorna</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
    <ProductDeleteDialog
      v-if="showProductDeleteDialog"
      v-model="showProductDeleteDialog"
      :product-id="productId"
      @confirmed="initData()"/>
    <ActivityDeleteDialog
      v-if="showActivityDeleteDialog"
      v-model="showActivityDeleteDialog"
      :activity-id="activityId"
      @confirmed="initData()"/>
  </v-container>
</template>

<script>
import If65Autocomplete from "../../components/widgets/If65Autocomplete.vue";
import ProductDeleteDialog from "../../views/projects/ProductDeleteDialog";
import ActivityDeleteDialog from "../../views/projects/ActivityDeleteDialog";
export default {
    components:{
      If65Autocomplete,
      ProductDeleteDialog,
      ActivityDeleteDialog
    },
    props: {
        projectId: {
            required: true
        }
    },
    data() {
      return {
        activitiesMenu: null,
        dialog: false,
        deleteAct: false,
        activity: {},
        dialogCloneActivity: false,
        product:{},
        selectedActivityId: null,
        selectedProduct: null,
        offsetTop: 0,
        filters: {},
        filterProject: null,
        filterActivity: null,
        showActivityDeleteDialog: false,
        activityId:null,
        showProductDeleteDialog: false,
        productId: null
      }
    },
    mounted() {
        this.initData()
    },
    computed: {
      prependExtraParams() {
        return this.projectId ? {project: this.projectId, choices: ''} : {choices: ''}
      },
        // used for watch
      menuSelected() {
        return `${this.selectedProduct}${this.selectedActivityId}`;
      }
    },
    watch: {
        menuSelected() {
            this.$emit('product-selected', this.selectedProduct, this.selectedActivityId)
        }
    },
    methods: {
      onScroll (e) {
          this.offsetTop = e.target.scrollTop
      },
      initData() {
          return this.$projectService.activities_menu(this.projectId).then(
              resp => {
                  this.activitiesMenu = resp.data
              }
          )
      },
      editProduct(productId, activityId) {
          this.selectedProduct = productId;
          this.selectedActivityId = activityId;
      },
      createProduct(activityId) {
          this.selectedProduct = 'NEW';
          this.selectedActivityId = activityId;
      },
      cloneProduct(product) {
        return this.$productService.clone(product)
          .then(resp => {
            this.initData()
            console.log(resp.data)
          })
      },
      cloneActivity(activity_id) {
        this.filterActivity = activity_id
        this.dialogCloneActivity = true
        console.log(activity_id)
      },
      saveCloneActivity() {
        console.log(this.filterProject, this.filterActivity)
        this.dialogCloneActivity = false
        const project_id = {
            id: this.filterProject
        }
        return this.$activityService.clone(this.filterActivity, project_id).then(() =>{
          this.$dtoast.pop({
            preset: "success",
            content: "Attività Clonata con successo",
          })
          this.initData()
        })

      },
      updateActivity(product) {
        this.dialog = true
        this.product = product
      },
      saveActivity() {
        this.product.activity = this.filters.activities[0]
        this.product.id = undefined
        this.product.activity_name = undefined
        this.dialog = false
        this.$productService.post(this.product)
          .then(() => {
              this.$dtoast.pop({
                preset: "success",
                content: "Prodotto inserito con successo",
              })
          }
          )
          .catch(error => {
            console.log(error)
            this.$dtoast.pop({
              preset: "error",
              content: `Esiste già un prodotto ${this.product.name} per questa Attività`,
            })
          })
      },
      deleteActivity(activity) {
        this.activityId = activity.id
        this.showActivityDeleteDialog = true
        console.log(activity)
      },
      deleteProduct(product) {
        this.productId = product.id
        this.showProductDeleteDialog = true
        console.log(this.productId)
        },
    }
}
</script>

<style scoped>
.border {
    border: 2px dashed #1976d2;
}
</style>
