<template>
    <div>
        <v-card v-if="isCard" class="mb-12" outlined elevation="6">
            <v-container :fluid="true">
                <slot name="step-content"></slot>
            </v-container>
        </v-card>
        <v-container v-else :fluid="true">
            <slot name="step-content"></slot>
        </v-container>
        <slot name="step-buttons">
            <v-btn color="primary" @click="$emit('next')">Avanti</v-btn>
            <v-btn text @click="$emit('previous')">Indietro</v-btn>
        </slot>
    </div>
</template>

<script>
export default {
    props: {
        isCard: {
            type: Boolean,
            default: true
        }
    }

}
</script>

<style scoped>

</style>