import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/dashboard/Dashboard.vue'
import Projects from '../views/projects/Projects.vue'
import DetailsProject from "../views/projects/DetailsProject";
import TasksProject from "../views/projects/TasksProject";
import Team from "../views/team/Team";
import Login from "../views/login/Login";
import Customers from "../views/customers/Customers";
import Providers from "../views/providers/Providers";
import Tasks from "../views/tasks/Tasks";

Vue.use(VueRouter)

// {
//   path: '/',
//       name: 'dashboard',
//     component: Dashboard,
//     meta: {
//   breadCrumb: [
//     {
//       text: 'Home'
//     }
//   ]
// }
// },
// {
//   path: '/projects',
//       name: 'projects',
//     component: Projects,
//     meta: {
//   breadCrumb(route: Route) {
//     const paramToPageB = route.params.paramToPageB;
//     return [
//       {
//         text: 'Home',
//         to: { name: 'Home' }
//       },
//       {
//         text: paramToPageB,
//       }
//     ]}
// }
// },

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects
  },
  {
    path: '/projects/:anno_riferimento',
    name: 'backup',
    component: Projects
  },
  {
    path: '/project/:projectId',
    name: 'detailsProject',
    component: DetailsProject,
    props: true,
  },
  {
    path: '/project/:projectId/tasks',
    name: 'tasksProject',
    component: TasksProject,
    props: true,
  },
  {
    path: '/team',
    name: 'team',
    component: Team
  },
  {
    path: '/customers',
    name: 'customers',
    component: Customers
  },
  {
    path: '/providers',
    name: 'providers',
    component: Providers
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: Tasks
  },

]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const token = JSON.parse(window.localStorage.getItem('token'))
  if (!token && to.name !== 'login') {
    next({name: 'login'})
  }
  next();
})
export default router
