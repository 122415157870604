<template>
    <step-base :is-card="false">
        <template #step-content>
            <v-row>
                <v-col cols="3">
                    <step3-selector ref="selector" :project-id="projectId" @product-selected="selectProduct"/>
                </v-col>
                <v-col cols="9" class="pt-5">
                    <step3-form
                        v-if="selectedProduct"
                        :product-id="selectedProduct"
                        :activity-id="selectedActivity"
                        @productChange="productChange"/>
                    <v-card class="blue-grey lighten-5" v-else>
                        <v-card-title>Seleziona il prodotto</v-card-title>
                        <v-card-text>Seleziona il prodotto da modificare o aggiungere</v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <template #step-buttons>
            <v-btn color="primary" type="submit" @click.prevent="onNext">Chiudi</v-btn>
            <v-btn
                v-if="$store.state.user.role === 'ADMIN'"
                text @click="onPrevious">Indietro</v-btn>
        </template>
    </step-base>
</template>

<script>
import StepBase from "./StepBase.vue";
import step3Form from "./Step3Form.vue"
import Step3Selector from "./Step3Selector.vue";

export default {
    components: {
        StepBase,
        step3Form,
        Step3Selector
    },
    props: {
        projectId: {
            default: null
        }
    },
    data() {
        return {
            activities: null,
            selectedActivity: null,
            selectedProduct: null
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        initData() {
            return this.$activityService.list({project: this.projectId}).then(
                resp => {
                    this.activities = resp.data;
                }
            )
        },
        onNext() {
            this.$emit('stepChanged', 4)
        },
        onPrevious() {
            this.$emit('stepChanged', 2)
        },
        productChange() {
            this.$refs['selector'].initData();
            this.$refs['selector'].selectedProduct = null;
            this.$emit('productChange');
        },
        selectProduct(productId, activityId) {
            this.selectedProduct = productId;
            this.selectedActivity = activityId;
        }
    }
}
</script>
