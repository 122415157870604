<template>
  <div class="team">
    <h1 class="text-sm-subtitle-1 grey--text">Team</h1>
  </div>
</template>

<script>
export default {
  name: "Team"
}
</script>

<style scoped>

</style>
