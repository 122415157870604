<template>
    <div>
        <v-combobox
            v-model="model"
            :items="items"
            :error-messages="modelErrors"
            :search-input.sync="search"
            :hint="hint"
            :label="label"
            :disabled="disabled"
            deletable-chips
            hide-selected
            :multiple=multiple
            persistent-hint
            small-chips
            clearable
            @input="$v.model.$touch()"
            @blur="$v.model.$touch()"
        >
            <template #prepend-inner>
                <v-btn icon @click="$emit('intialize')">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </template>
            <template v-if="items.length > 0" #no-data>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            Non ci sono risultati "<strong>{{ search }}</strong>". Premi <kbd>enter</kbd> per crearne uno nuovo
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-combobox>
    </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    validations: {
        model: {required}
    },
    data() {
        return {
            model: [],
            search: null,
        }
    },
    props: {
        hint: {
            type: String,
            default: 'Digita il valore e premi invio o tab per confermare'
        },
        items: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            required: true
        },
        multiple: {
            type: Boolean,
            default: true
        },
        required: {
            type: Boolean,
            default: false
        },
        value: {
            required: true
        },
        disabled: {
          type: Boolean,
          default: false
        }
    },
    computed: {
        modelErrors() {
            const errors = [];
            if (this.required && this.$v.model.$dirty && !this.$v.model.required) {
                errors.push('Campo richiesto');
            }
            return errors;
        },
    },
    watch: {
        value: {
            handler() {
                this.model = this.value
            },
            immediate: true
        },
        model() {
            this.$emit('input', this.model)
        }
    },
    methods: {}
}</script>
