<template>
    <div v-if="items !== null">
        <v-autocomplete
            id="if65Select"
            v-model="model"
            :error-messages="modelErrors"
            :hint="hint"
            :items="items"
            :label="label"
            :loading="isLoading"
            :multiple="multiple"
            :placeholder="placeholder"
            :search-input.sync="search"
            :prepend-icon="icon"
            :disabled="disabled"
            hide-no-data
            chips
            :dark="dark"
            :dense="dense"
            deletable-chips
            :flat="flat"
            hide-selected
            persistent-hint
            @input="$v.model.$touch()"
            @blur="$v.model.$touch()"
        >
        </v-autocomplete>
    </div>
</template>

<script>

import _ from "lodash";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    validations: {
        model: {required}
    },
    props: {
        api: {
            type: Function,
            required: true
        },
        clearable: {
            type: Boolean,
            default: true
        },
        dense: {
            type: Boolean,
            default: true
        },
        extraItems: {
            // [{value: 'xxx', text: 'The xxx choice'}, {}, ...]
            default: () => {
                return []
            }
        },
        extraParams: {
            type: Object,
            default: () => {
                return {}
            }
        },
        flat: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
        },
        hint: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            required: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        nullItem: {
            default: () => {
                return {value: null, text: ''}
            }
        },
        placeholder: {
            type: String,
            default: 'Ricerca'
        },
        required: {
            type: Boolean,
            default: false
        },
        dark: {
          type: Boolean,
          default: false
        },
        value: {
            required: true
        },
        disabled: {
          type: Boolean,
          default: false
        }
    },
    data() {
        return {
            descriptionLimit: 60,
            isLoading: true,
            items: null,
            model: null,
            search: '',
        }
    },
    computed: {
        modelErrors() {
            const errors = [];
            if (this.required && this.$v.model.$dirty && !this.$v.model.required) {
                errors.push('Campo richiesto');
            }
            return errors;
        },
    },
    mounted() {
        this.loadItems()
    },
    watch: {
        value: {
            handler() {
                this.model = this.value
            },
            immediate: true
        },
        model() {
            this.$emit('input', this.model ? this.model : this.nullItem.value)
        },
        search() {
            this.isLoading = true
            this.loadItems();
        },
    },
    methods: {
        loadItems() {
            const params = Object.assign(this.extraParams, {choices: ''})
            return this.api(params)
                .then(
                    response => {
                        this.$emit('progress', false)
                        const items = _.cloneDeep(response.data)
                        for (const extraItem of this.extraItems) {
                            items.push(extraItem)
                        }
                        this.items = items;
                        this.$emit('items-loaded', items)

                    }
                )
                .finally(() => (this.isLoading = false))
        }
    },
}
</script>
