import axios from 'axios';
import authHeader from '/src/services/auth.header';

const API_URL = process.env.VUE_APP_API_URL

class TaskService {
    get(id, params = {}) {
        return axios.get(
            `${API_URL}task/${id}/`,
            {headers: authHeader(), params: params})
    }

    patch(id, data = {}) {
        return axios.patch(
            `${API_URL}task/${id}/`,
            data,
            {headers: authHeader()}
        )
    }

    post(data = {}) {
        return axios.post(
            `${API_URL}task/`,
            data,
            {headers: authHeader()}
        )
    }

    list(params) {
        return axios.get(
            `${API_URL}task/`,
            {headers: authHeader(), params: params}
        )
    }

    delete(id) {
        return axios.delete(
            `${API_URL}task/${id}/`,
            {headers: authHeader()}
        )
    }
    exportExcel(params) {
        return axios.get(
            `${API_URL}task/export_in_excel/`,
            {headers: authHeader(), responseType: 'blob', params:params})
    }
}

export default new TaskService();
