import axios from 'axios';
import authHeader from '/src/services/auth.header';
const API_URL = process.env.VUE_APP_API_URL


class SubActivityService {
    get(id, params = {}) {
        return axios.get(
            `${API_URL}sub_activity/${id}/`,
            {headers: authHeader(), params: params})
    }

    list(params) {
        return axios.get(`${API_URL}sub_activity/`, {
            headers: authHeader(),
            params: params
        })
    }

    check(productId, sub_activities) {
        return axios.post( `${API_URL}sub_activity/check/`,
            {
                product_id: productId,
                sub_activities: sub_activities
            },
            {headers: authHeader()}
        )
    }
}

export default new SubActivityService();
