<template>
    <div>
        <v-card elevation="0">
            <v-data-table
                :headers="headers"
                :items="details"
                :itemsPerPage="itemsPerPage"
                hide-default-footer
                dense
            >
                <template v-slot:item.show_expiration_alert="{ item }">
                    <div v-if="item.show_expiration_alert">
                        <v-btn icon @click="snackbar = true">
                            <v-icon small :color="!item.expires_tomorrow ? 'red' : 'grey'">
                                mdi-bell-badge-outline
                            </v-icon>
                        </v-btn>
                        <v-snackbar v-model="snackbar" :timeout="-1" absolute bottom color="primary" left text>
                            il Prodotto ha un'anomalia!
                            <template v-slot:action="{ attrs }">
                                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                                    Close
                                </v-btn>
                            </template>
                        </v-snackbar>
                    </div>
                </template>
                <template v-slot:item.delivery_date="{ item }">
                  {{ formatDate(item.delivery_date)}}
                </template>
                <template v-slot:item.count="{item, index}">
                    {{ index + 1 }}
                </template>
                <template #item.operator_name="{ item }">
                  <v-select
                      class="custom"
                      @input="salvaOperatori(item)"
                      dense
                      v-model="item.operators"
                      :menu-props="{ top: true, offsetY: true }"
                      :items="operatori"
                      item-value="id"
                      item-text="full_name"
                      multiple
                      persistent-hint
                  >
                    <template #selection="{ item }">
                      <v-chip dark x-small color="primary">{{item.last_name}}</v-chip>
                    </template>
                  </v-select>
<!--                    <div v-for="(name, index) in item.operator_name" :key="index" class="d-inline-flex">-->
<!--                        <v-chip x-small dark label color="if65Blue" class="ml-1">-->
<!--                            {{ name }}-->
<!--                        </v-chip>-->
<!--                    </div>-->
                </template>
                <template #item.status="{ item }">
                  <v-select
                      class="custom"
                      @input="salvaStato(item)"
                      chips
                      dense
                      :menu-props="{ top: true, offsetY: true }"
                      v-model="item.status"
                      :items="stati"
                      item-text="text"
                      item-value="value">
                    <template #selection="{ item }">
                      <v-chip dark x-small :color="getColor(item.value)">{{item.text}}</v-chip>
                    </template>
                  </v-select>
                </template>
                <template #item.actions="{ item, index }">
                    <v-tooltip top v-if="item.notified">
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn :loading="loading[index]" @click="sendEmail(item, loading[index] = true)" text small v-on="tooltip">
                                <v-icon style="color: darkolivegreen" small>mdi-email-check-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Notifica Inviata</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn :loading="loading[index]" @click="sendEmail(item, loading[index] = true)" text small v-on="tooltip">
                                <v-icon small>mdi-email-fast</v-icon>
                            </v-btn>
                        </template>
                        <span>Invia Notifica</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
// import If65Autocomplete from '../widgets/If65Autocomplete.vue'


import {format, parseISO} from "date-fns";
// import If65Autocomplete from "@/components/widgets/If65Autocomplete.vue";

export default {
    name: "ListDetails",
  props: {
    id: {
      required: true
    },
    projectCode: {
      required: true
    },
    statusToSearch: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      headers: [
        { text: '', value: 'show_expiration_alert', class: "if65Blue white--text", width: '5px', sortable:false },
        { text: 'Scadenza', value: 'delivery_date', class: "if65Blue white--text", sortable:false},
        { text: 'N#', value: 'count', class: "if65Blue white--text", sortable:false },
        { text: 'Nome Attività', value: 'activity_name', class: "if65Blue white--text", sortable:false },
        { text: 'Prodotto', value: 'name', class: "if65Blue white--text", sortable:false },
        { text: 'Operatori', value: 'operator_name', class: "if65Blue white--text", sortable:false },
        { text: 'Stato', value: 'status', class: "if65Blue white--text", sortable:false },
        { text: 'Actions', value: 'actions', class: "if65Blue white--text", sortable:false },
      ],
      details: [],
      loading: {},
      itemsPerPage: 50,
      snackbar: false,
      stato:'',
      stati: [
        {value:'DAASSEGNARE', text: 'Da Assegnare'},
        {value:'ASSEGNATO', text: 'Assegnato'},
        {value:'INLAVORAZIONE', text: 'In Lavorazione'},
        {value:'INAPPROVAZIONE', text: 'In Approvazione'},
        {value:'APPROVATO', text: 'Approvato'},
        {value:'INVALFORNITORE', text: 'Inv. al Fornitore'},
        {value:'LAVOROCHIUSO', text: 'Lavoro Chiuso'}
      ],
      operatori:[]
    }
  },
  mounted() {
    this.initDetails()
    this.getOperatori()
  },
  watch: {
    value: {
      handler() {
        this.show = this.value
      },
      immediate: true
    },
  },
  methods: {
    formatDate(isoDate) {
      return isoDate ? format(parseISO(isoDate), 'dd/MM/yyyy') : ''
    },
    initDetails() {
      this.$productService.list({project: this.id, status:this.statusToSearch})
          .then(resp => {
            this.details = resp.data
          })
    },
    getColor (status) {
      if (status === 'DAASSEGNARE') return 'red darken-1'
      else if (status === 'ASSEGNATO') return 'blue darken-1'
      else if (status === 'INLAVORAZIONE') return 'green darken-1'
      else if (status === 'INAPPROVAZIONE') return 'grey darken-1'
      else if (status === 'INVALFORNITORE') return  'grey darken-1'
      else if (status === 'LAVOROCHIUSO') return  'green darken-4'
      else return  'pink lighten-4'
      // DAASSEGNARE
      // ASSEGNATO
      // INLAVORAZIONE
      // INAPPROVAZIONE
      // APPROVATO
      // INVALFORNITORE
      // LAVOROCHIUSO
    },
    sendEmail(item) {
      item.notified = true
      this.$projectService.sendNotifications(item)
          .then(resp => {
            console.log(resp)
            this.loading = {}
            this.initDetails()
            this.$dtoast.pop({
              rounded:"pill",
              preset: "success",
              content: `${resp.data.message}`,
            })
          }).catch(xhr => {
        this.$root.$emit('renderError', xhr)
      })

    },
    salvaOperatori(item) {
      if (item.operators.length === 1 && item.status === 'DAASSEGNARE') {
        let status = {
          operators: item.operators,
          status: 'ASSEGNATO'
        }
        this.$productService.patch(item.id, status)
        .then(() => {
            this.loading = {}
            this.initDetails()
            this.$dtoast.pop({
              rounded:"pill",
              preset: "success",
              content: `Stato Cambiato con Successo`,
            })
          }).catch(xhr => {
        this.$root.$emit('renderError', xhr)
      })
      } else {
        let data = {
          operators: item.operators
        }
        this.$productService.patch(item.id, data)
            .then(() => {
              this.loading = {}
              this.initDetails()
              this.$dtoast.pop({
                rounded: "pill",
                preset: "success",
                content: `Stato Cambiato con Successo`,
              })
            }).catch(xhr => {
          this.$root.$emit('renderError', xhr)
        })
      }
    },
    salvaStato(item) {
      let data = {
        status: item.status
      }
      this.$productService.patch(item.id, data)
      .then(() => {
            this.loading = {}
            this.initDetails()
            this.$dtoast.pop({
              rounded:"pill",
              preset: "success",
              content: `Stato Cambiato con Successo`,
            })
          }).catch(xhr => {
        this.$root.$emit('renderError', xhr)
      })
    },
    getOperatori() {
      this.$userService.list()
          .then(resp => {
            this.operatori = resp.data
          })
          .catch()
    }
  }
}
</script>

<style scoped>
.v-progress-circular {
    margin: 1rem;
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
</style>
