<template>
  <v-app id="login" class="red">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-0 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <v-img src="static/image/logo.png" alt="Italbrix Logo" width="220" height="110"></v-img>
                  <v-container>
                    <v-layout row>
                      <v-flex xs12>
                        <v-text-field
                            name="username"
                            label="Username"
                            id="username"
                            v-model="username"
                            type="username"
                            required
                            prepend-icon="mdi-account"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout row>
                      <v-flex xs12>
                        <v-text-field
                            name="password"
                            label="Password"
                            id="password"
                            v-model="password"
                            :type="showPassword ? 'text' : 'password'"
                            prepend-icon="mdi-lock"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"
                            required
                            @keydown.enter="login"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn block color="primary" @click="login" :loading="loading">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>

</template>

<script>
import UserService from '/src/services/user.service.js';

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      username: '',
      password: '',
      showPassword: ''
    }
  },
  mounted() {
  },
  methods: {
    getCookie(name) {
      if (!document.cookie) {
        return null;
      }
      const xsrfCookies = document.cookie.split(';')
          .map(c => c.trim())
          .filter(c => c.startsWith(name + '='));

      if (xsrfCookies.length === 0) {
        return null;
      }
      return decodeURIComponent(xsrfCookies[0].split('=')[1]);
    },
    login() {
      console.log('riga 87')
      UserService.login(this.username, this.password).then(
          response => {
            localStorage.setItem('token', JSON.stringify(response.data.key));
            const csrfToken = this.getCookie('csrftoken');
            console.log('CSRF Token:', csrfToken); // Aggiungi per debug
            localStorage.setItem('csrf_token', csrfToken)
            this.$store.dispatch('setCurrentUser').then(() => {
              this.$router.push({name: 'projects'});
              this.$dtoast.pop({
                preset: "success",
                content: "Credenziali corrette",
              })
            });
          }
      ).catch(
          () => {
            this.$dtoast.pop({
              preset: "error",
              content: "Credenziali errate",
            })
          }
      )
    }
  }
}
</script>

<style scoped lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>
