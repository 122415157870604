<template>
  <v-menu
    min-width="200px"
    rounded
    offset-y
    tile
  >
    <template v-slot:activator="{ on }">
      <v-app-bar-nav-icon
        class="ml-4"
        icon
        small
        v-on="on"
      >
        <v-badge
          overlap
          content="0"
          bordered
          color="primary"
        >
          <v-icon color="if65Silver" @click="alert">mdi-bell</v-icon>
        </v-badge>
      </v-app-bar-nav-icon>
    </template>
    <v-card>
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
          <v-avatar
            color="white"
          >
            <v-icon>mdi-bell</v-icon>
          </v-avatar>
          <h3>Notifiche</h3>
          <p class="text-caption mt-1">
            ultime notifiche o alert
          </p>
          <v-divider class="my-3"></v-divider>
          <p class="text-caption px-3">
            Non ci sono notifiche al momento
          </p>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: 'Alert',
  props: {
    alert: {}
  }
}
</script>
