<template>
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>Configura progetto</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <project-stepper
                :initial-project-id="projectId"
                :clone="clone"
                @completed="show = false"/>
        </v-card>
    </v-dialog>
</template>

<script>
import ProjectStepper from "./ProjectStepper/ProjectStepper.vue";

export default {
    components: {
        ProjectStepper
    },
    props: {
        projectId: {
            default: null
        },
        clone: {
            default: false
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            show: false,
        }
    },
    watch: {
        value: {
            handler() {
                this.show = this.value
            },
            immediate: true
        },
        show() {
            this.$emit('input', this.show)
        }
    }
}
</script>
