import Vue from 'vue'
import Vuex from 'vuex'

import UserService from '/src/services/user.service.js';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null
    },
    mutations: {
        commitCurrentUser(state, user) {
            state.user = user;
            window.localStorage.setItem('user', JSON.stringify(user))
        },
        commitLogout(state) {
            state.user = {
                "id":null,
                "last_login": null,
                "username": null,
                "first_name":null,
                "last_name": null,
                "email":null,
                "date_joined":null,
                "role":"LOGOUT",
                "full_name":null
              }
            state.token = null;
        },
    },
    actions: {
        setCurrentUser(context) {
            return UserService.self()
                .then(
                    user => {
                        context.commit('commitCurrentUser', user.data)
                    }
                )
                .catch(
                    () => {
                        context.commit('commitCurrentUser', null)
                        window.localStorage.removeItem('token');
                        this.$router.push({name: 'login'});
                    }
                )
        },
        logout(context) {
            return UserService.logout().then(() => {
                context.commit('commitLogout');
                window.localStorage.clear()
            });
        },
    },
    modules: {}
})
