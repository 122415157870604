<template>
    <step-base @next="onNext" @previous="onPrevious">
        <template #step-content>
            <if65-tag-selector
                v-if="activityNames" v-model="activityNames" :items="defaultActivities" label="Attività"
                @intialize="initData"/>
            <step2-activity-check-dialog
                v-model="showActivityCheckModal"
                :activities-with-products="activitiesWithProducts"
                @canceled="initData"
                @confirmed="saveActivities"
            />
        </template>
    </step-base>
</template>

<script>
import If65TagSelector from "../widgets/If65TagSelector.vue";
import Step2ActivityCheckDialog from "./Step2ActivityCheckDialog.vue";
import StepBase from "./StepBase.vue";

export default {
    components: {
        If65TagSelector,
        Step2ActivityCheckDialog,
        StepBase
    },
    props: {
        projectId: {
            required: true
        }
    },
    data() {
        return {
            activityNames: null,
            defaultActivities: [],
            activitiesWithProducts: [],
            showActivityCheckModal: false
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        initData() {
            this.$activityService.list().then(
                resp => {
                    this.defaultActivities = resp.data.reduce((array, item) => {
                        array.push(item.name)
                        return array
                    }, [])
                }
            )
            this.$activityService.list({project: this.projectId}).then(
                resp => {
                    this.activityNames = resp.data.reduce((array, item) => {
                        array.push(item.name)
                        return array
                    }, [])
                }
            )
        },
        onNext() {
            this.checkActivities().then(
                () => {
                    if (this.activitiesWithProducts.length) {
                        this.showActivityCheckModal = true;
                    } else {
                        this.saveActivities().then(
                            () => this.$emit('stepChanged', 3)
                        );
                    }
                }
            );
        },
        onPrevious() {
            this.checkActivities().then(
                () => {
                    if (this.activitiesWithProducts.length) {
                        this.showActivityCheckModal = true;
                    } else {
                        this.saveActivities().then(
                            () => this.$emit('stepChanged', 1)
                        );
                    }
                }
            );
        },
        checkActivities() {
            return this.$activityService.check(this.projectId, this.activityNames).then(
                response => {
                    this.activitiesWithProducts = response.data;
                }
            ).catch(xhr => {
                this.$root.$emit('renderError', xhr)
            })
        },
        saveActivities() {
            return this.$projectService.save_activities(
                this.projectId, this.activityNames
            ).then(
                this.initData
            ).catch(xhr => {
                this.$root.$emit('renderError', xhr)
            });
        }
    }
}
</script>
