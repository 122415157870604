<template>
    <v-menu
        v-model="show"
        :close-on-content-click="false"
        max-width="290"
    >
        <template #activator="{ on, attrs }">
            <v-text-field
                :prepend-icon="icon"
                :value="formattedDate"
                clearable
                :error-messages="modelErrors"
                :label="label"
                :disabled="disabled"
                :dense="dense"
                readonly
                v-bind="attrs"
                v-on="on"
                @input="$v.model.$touch()"
                @blur="$v.model.$touch()"
                @click:clear="model = null"
            >
            </v-text-field>
        </template>
        <v-date-picker
            :range="range"
            v-model="model"
            :allowed-dates="allowedDates"
            :first-day-of-week="1"
            :disabled="disabled"
            @change="show = false"
            @input="$v.model.$touch()"
            @blur="$v.model.$touch()"
            locale="it-it"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import {format, parseISO} from 'date-fns'
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import { it } from 'date-fns/locale'
export default {
    mixins: [validationMixin],
    validations: {
        model: {required}
    },
    props: {
        allowedDates: {
            type: Function,
            default: () => true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        dateFormat: {
            type: String,
            default: 'EEEE, dd/MM/yyyy'
        },
        range: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            required: true
        },
        value: {
            required: true
        },
        icon : {
          type: String
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            model: null,
            show: false
        }
    },
    computed: {
        formattedDate() {
            if (this.model) {
                if (this.range) {
                    return this.model.map(item => format(parseISO(item), 'dd/MM/yyyy'))
                } else {
                    return format(parseISO(this.model), this.dateFormat, {locale: it})
                }
            }
            return ''
        },
        modelErrors() {
            const errors = [];
            if (this.required && this.$v.model.$dirty && !this.$v.model.required) {
                errors.push('Campo richiesto');
            }
            return errors;
        },
    },
    watch: {
        value: {
            handler() {
                this.model = this.value
            },
            immediate: true
        },
        model() {
            this.$emit('input', this.model)
        }
    }
}
</script>
