import axios from 'axios';
import authHeader from '/src/services/auth.header';
const API_URL = process.env.VUE_APP_API_URL


class ActivityService {
    get(id, params = {}) {
        return axios.get(
            `${API_URL}activity/${id}`,
            {headers: authHeader(), params: params})
    }

    list(params) {
        return axios.get(`${API_URL}activity/`, {
            headers: authHeader(),
            params: params
        })
    }

    check(projectId, activities) {
        return axios.post( `${API_URL}activity/check/`,
            {
                project_id: projectId,
                activities: activities
            },
            {headers: authHeader()}
        )
    }
    clone(activity, project_id) {
      return axios.post(`${API_URL}activity/${activity}/clone/`, project_id, { headers: authHeader()})
    }

    delete(id) {
      return axios.delete(`${API_URL}activity/${id}`, { headers: authHeader()})
    }
}

export default new ActivityService();
