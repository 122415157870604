<template>
  <div>
    <v-overlay :value="loading">
      <v-breadcrumbs
          divider=">"
      ></v-breadcrumbs>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-overlay
      :value="excelLoading"
      :absolute="absolute"
    >
      <v-progress-circular
        :rotate="360"
        :size="100"
        :width="15"
        :value="value"
        color="white"
      >
        {{ value }}%
      </v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="4">
        <v-card elevation="2" class="italbrix white--text">
          <v-card-title>
            <span>Seleziona un Progetto:</span>
          </v-card-title>
          <v-card-actions>
            <if65-autocomplete
              v-model="projectId"
              :label='``'
              :dense="true"
              :api="projectApi"
              :dark="true"
              :placeholder="``"
              :flat="true" />
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4" v-if="this.tasks.length > 1 && ['ADMIN','HEAD', 'PURCHASE_OPERATOR'].includes(this.$store.state.user.role)">
        <v-card elevation="2" class="italbrix white--text">
          <v-card-title>
            <span>Ore del giorno {{ todayDate }}:</span>
          </v-card-title>
          <v-virtual-scroll
            v-if="tasksStatsYesterday.length > 0"
            :bench="benched"
            :items="tasksStatsYesterday"
            height="200"
            item-height="20"
          >
            <template v-slot:default="{ item }">
              <v-list-item class="row--dense white--text">
                <v-list-item-content >
                  <v-list-item-title style="border-bottom: solid #fff 1px">
                    <small><span>{{ item.operator.full_name }}</span> <span class="float-right">{{ item.hours }} ore</span></small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card elevation="2" class="italbrix white--text">
          <v-card-title>
            <span>Statistiche Ore:</span>
          </v-card-title>
          <v-card-text>
            <small class="white--text" v-if="tasksStatsYesterday[0] != null">Ore del giorno: <b>{{ totalHoursYesterday }}</b> ore</small>
            <small class="white--text" v-else>Ore del giorno: <b>0</b> ore</small>
            <br>
<!--            <small class="white&#45;&#45;text">Ore totali mese: <b>87</b> ore [ fake hours ]</small>-->
<!--            {{ tasksStatsYesterday[0] }}-->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="2">
          <v-card-title>
            <v-row>
              <v-col>Tasks: {{ tasksCount }}</v-col>
              <v-col>
                  <span
                    class="float-end">
                      <v-btn
                        color="success"
                        target="_parent"
                        :loading="excelLoading"
                        @click="exportInExcel">
                          <v-icon dark>
                              mdi-microsoft-excel
                          </v-icon>
                      </v-btn>
                  </span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="tasks"
            :options.sync="dtOptions"
            :items-per-page="50"
            :server-items-length="tasksCount"
            :footer-props="{'items-per-page-options': [50]}"
            :loading="loading"
            loading-text="Loading... Please wait"
            dense
            class="elevation-1">
            <template #body.prepend>
              <tr class="pb-2 mb-5">
                <td>
                  <if65-autocomplete
                    v-model="filters.customer"
                    :api="$customerService.list"
                    :extra-params="prependExtraParams"
                    :dark="false"
                    :label='``'
                    :placeholder="``"
                    hint="Insegna..."/>
                </td>
                <td>
                  <if65-autocomplete
                    :dense="true"
                    v-model="filters.operators"
                    :api="$userService.list"
                    :extra-params="prependExtraParams"
                    :multiple="true"
                    :dark="false"
                    :label='``'
                    :placeholder="``"
                    hint="Operatore..."/>
                </td>
                <td>
                  <if65-autocomplete
                    v-model="projectId"
                    hint="Codice..."
                    :api="projectApi"
                    :dark="false"
                    :placeholder="``"
                    :label='``'></if65-autocomplete>
                </td>
                <td>
                  <if65-autocomplete
                    v-model="filters.project_name"
                    :api="projectApi"
                    :extra-params="{choices_with_name:''}"
                    multiple
                    :dark="false"
                    :label='``'
                    :placeholder="``"
                    hint="Progetto...">
                  </if65-autocomplete>
                </td>
                <td>
                  <if65-autocomplete
                    v-model="filters.activities"
                    :api="$activityService.list"
                    :extra-params="prependExtraParams"
                    :multiple="true"
                    :dark="false"
                    :label="``"
                    :placeholder="``"
                    :flat="true"
                    :hint="`Attività...`"/>
                </td>
                <td>
                  <if65-autocomplete
                    v-model="filters.products"
                    :api="$productService.list"
                    :extra-params="prependExtraParams"
                    :multiple="true"
                    :dark="false"
                    :label='``'
                    :placeholder="``"
                    hint="Prodotto..."/>
                </td>
                <td>
<!--                  <if65-autocomplete
                    v-model="filters.sub_activities"
                    hint="Sub Act..."
                    :api="$subActivityService.list"
                    :extra-params="prependExtraParams"
                    :multiple="true"
                    :dark="false"
                    :placeholder="``"
                    :label='``'>
                  </if65-autocomplete>-->
                </td>
                <td>
                  <div style="width: 150px; font-size: 10px">
                    <if65-date-picker
                      v-model="createdAtRangeToSearch"
                      :label='``'
                      :placeholder="``"
                      :dense="true"
                      :range="true"
                      :icon="``"
                      dateFormat="dd/MM/yyyy"
                      clearable
                    >
                    </if65-date-picker>
                  </div>
                </td>
                <td>
                  <v-text-field
                    dense
                    flat
                    v-model="orderNumberToSearch"
                    hide-details="auto"
                    persistent-hint
                    hint="N° Ordine"
                    clearable>
                  </v-text-field>
                </td>
                <td>
                </td>
                <td></td>
              </tr>
              <div class="mb-3"></div>
            </template>
            <template v-slot:item.operator_full_name="{item}">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{item.operator_full_name }}
                  </span>
                </template>
                <span>{{item.operator_full_name }}</span>
              </v-tooltip>
            </template>
            <template #item.project_name="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ item.project_name }}
                  </span>
                </template>
                <span>{{item.project_name }}</span>
              </v-tooltip>
            </template>
            <template #item.product_name="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{item.product_name}}
                  </span>
                </template>
                <span>{{item.product_name }}</span>
              </v-tooltip>
            </template>
            <template #item.task_date="{ item }">
              {{ formatDate(item.task_date) }}
            </template>
            <template #item.actions="{ item }">
              <v-row>
                <v-icon
                  small class="mr-2"
                  @click="editTask(item)">
                  mdi-pencil
                </v-icon>
                <v-icon
                  small class="mr-2"
                  :disabled="!canManipulate(item)"
                  @click="deleteTask(item)">
                  mdi-delete
                </v-icon>
                <task-delete-dialog
                  v-if="showTaskDeleteDialog"
                  v-model="showTaskDeleteDialog"
                  :task-id="item.id"
                  :task="task"
                  @confirmed="onTaskDeleteConfirmed"
                />
              </v-row>
            </template>
            <template #item.hours="{ item }">
              <v-chip
                close-icon="mdi-close-outline"
                color="green"
                text-color="white"
                x-small
              >{{ item.hours}} ore</v-chip>
            </template>
            <template #item.description_truncated="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ item.description_truncated }}</span>
                </template>
                <span>{{ item.description }}</span>
              </v-tooltip>
            </template>
            <template v-if="this.tasksCount > 1" v-slot:footer >
              <v-spacer></v-spacer>
              <small class="float-md-end mt-2 mr-2" style="color: grey-darken-2">Ore totali: <b>{{ totalHours }}</b> dal  <b>{{formatDate(firstDate, 'YYYY-MM-dd')}}</b></small>
<!--              <v-text-field-->
<!--                dense-->
<!--                disabled-->
<!--                style="position: absolute; font-size: 12px"-->
<!--                class="mt-2 pl-2"-->
<!--                :messages="`Ore Totali dal ${formatDate(ultimaData.task_date)}`"-->
<!--                :value="totalHours"-->
<!--              ></v-text-field>-->
            </template>
          </v-data-table>

          <task-dialog
            v-if="showTaskDialog"
            v-model="showTaskDialog"
            :task-id="taskToEdit"
            @taskSaved="onTaskSaved"/>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import If65DatePicker from "../../components/widgets/If65DatePicker.vue";
import TaskDeleteDialog from "./TaskDeleteDialog.vue";
import TaskDialog from "./TaskDialog.vue";
import _ from "lodash";
import {format, parseISO} from "date-fns";
import FileSaver from 'file-saver'
import moment from "moment";
import if65Autocomplete from "../../components/widgets/If65Autocomplete";

export default {
  components: {if65Autocomplete, If65DatePicker, TaskDeleteDialog, TaskDialog},
  data() {
    return {
      createdAtRangeToSearch: [],
      firstDate: '',
      benched: 0,
      filters: {},
      dtOptions: {},
      tasksCount: 0,
      project: null,
      projectId: null,
      headers: [
        {class: "", text: 'Insegna', value: 'customer', width: '10%'},
        {class: "", text: 'Operatore', value: 'operator_full_name', width: '10%'},
        {class: "", text: 'Codice', value: 'project_code', width: '10%'},
        {class: "", text: 'Progetto', value: 'project_name', width: '30%'},
        {class: "", text: 'Attività', value: 'activity_name', width: '15%'},
        {class: "", text: 'Prodotto', value: 'product_name', width: '30%'},
        {class: "", text: 'Sub Act', value: 'sub_activity_name', width: '15%'},
        {class: "", text: 'Data Creazione', value: 'task_date', width: '5%'},
        {class: "", text: 'N. d\'ordine', value: 'order_number', width: '10%'},
        {class: "", text: 'Ore', value: 'hours', align: 'end'},
        {class: "", text: 'Azioni', value: 'actions', sortable: false},
      ],
      showTaskDeleteDialog: false,
      showTaskDialog: false,
      tasks: [],
      taskToEdit: null,
      tasksStats: [],
      tasksStatsYesterday: [],
      orderNumberToSearch: '',
      codeNumberToSearch: '',
      task: null,
      loading: true,
      excelLoading: false,
      //
      interval: {},
      value: 0,
      absolute:false,
    }
  },
  props: {
    selectedProjectId: {
      default: null
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  watch: {
    excelLoading ()  {
      if (this.excelLoading) {
        this.interval = setInterval(() => {
          if (this.value === 100) {
            return (this.value = 0)
          }
          this.value += 5
        }, 1000)
      }
    },
    "dtOptions.page" (){
      this.initTasks()
      this.loading = true
    },
    loading (val) {
      val && setTimeout(() => {
        this.loading = false
      }, 3000)
    },
    projectId: {
      handler() {
        this.loading = true
        this.initProject();
      }
    },
    filters: {
      handler() {
        this.loading = true
        this.initTasks();
      },
      deep: true,
    },
    orderNumberToSearch() {
      this.loading = true
      this.initTasks();
    },
    createdAtRangeToSearch() {
      if (this.createdAtRangeToSearch && this.createdAtRangeToSearch.length >= 2) {
        this.loading = true
        this.dtOptions.page = 1
        this.initTasks();
      } if (this.createdAtRangeToSearch === null) {
        this.loading = true
        this.createdAtRangeToSearch = []
        setTimeout(() => {
          this.loading = false
        }, 5000)
        this.initTasks()
      }
    },
  },
  mounted() {
    this.projectId = this.$route.params.projectId || this.selectedProjectId
    this.initProject();
  },
  computed: {
    yesterdayDate() {
      return moment().subtract(1, "days").format("DD-MM-YYYY");
      // const today = new Date();
      // const yesterday = new Date();
      // yesterday.setDate(today.getDate() - 1);
      // return yesterday
    },
    todayDate() {
      return moment().format("DD-MM-YYYY");
    },
    prependExtraParams() {
      return this.projectId ? {project: this.projectId, choices: ''} : {choices: ''}
    },
    projectApi() {
      return this.$projectService.list;
    },
    listParams() {
      return {
        page: this.dtOptions.page || 1,
        firstDate: '',
        ...(this.filters.customer ? {customer: this.filters.customer} : {}),
        ...(this.projectId ? {project: this.projectId} : {}),
        ...{order_number: this.orderNumberToSearch},
        ...{code_number: this.codeNumberToSearch},
        ...{created_at_range: this.createdAtRangeToSearch.join(',')},
        ...(this.filters.operators ? {operators: this.filters.operators.join(',')} : {}),
        ...(this.filters.project_name ? {project_name: this.filters.project_name.join(',')} : {}),
        ...(this.filters.activities ? {activities: this.filters.activities.join(',')} : {}),
        ...(this.filters.products ? {products: this.filters.products.join(',')} : {}),
        ...(this.filters.sub_activities ? {sub_activities: this.filters.sub_activities.join(',')} : {}),
      }
    },
    ordering() {
      const {sortBy, sortDesc, page, pagePerItem} = this.dtOptions
      console.log(page, pagePerItem)
      if (sortBy && sortDesc && sortBy.length === 1 && sortDesc.length === 1) {
        return {ordering: `${sortDesc[0] ? '-' : ''}${sortBy[0]}`}
      }
      return {}
    },
    totalHours() {
      return _.sumBy(this.tasksStats, item => Number(item.hours));
    },
    totalHoursYesterday() {
      return _.sumBy(this.tasksStatsYesterday, item => Number(item.hours));
    }
  },
  methods: {
    initialOperator(full_name) {
      var names = full_name.split(' '),
          initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },
    truncateString(str, num) {
        if (str.length > num) {
          return str.slice(0, num) + "...";
        } else {
          return str;
        }
      },
    formatDate(isoDate) {
      return isoDate ? format(parseISO(isoDate), 'dd/MM/yyyy') : '-'
    },
    canManipulate(task) {
      return (
        this.$store.state.user.role === 'ADMIN' || this.$store.state.user.id === task.operator
      )
    },
    deleteTask(item) {
      this.task = item
      this.showTaskDeleteDialog = true;
    },
    editTask(item) {
      this.taskToEdit = item.id
      console.log(item)
      this.showTaskDialog = true;
    },
    initProject() {
      this.project = null;
      if (this.projectId) {
        this.$projectService.get(this.projectId, {detail: ''}).then(
          resp => {
            if (
              resp.data.operator_ids.includes(this.$store.state.user.id) ||
              this.$store.state.user.role === 'ADMIN'
            ) {
              this.project = resp.data.name
              this.initTasks()

            }
          }
        )
      } else {
        this.initTasks()
      }
    },
    initTasks() {
      this.$taskService.list(this.listParams).then(
        resp => {
          this.tasks = resp.data.results;
          this.tasksCount = resp.data.count
          const lastTask = _.last(resp.data.results)
          this.firstDate = lastTask ? lastTask.task_date : '-'
          this.loading = false
        }
      )
      if(this.dtOptions.page === 1) {
        this.$taskService.list({...this.listParams, ...{minutes_by_operator: ''}}).then(
          resp => {
            this.tasksStats = resp.data.results;
          }
        )
      }
      this.$taskService.list({...this.listParams, ...{minutes_by_operator_yesterday: ''}}).then(
        resp => {
          this.tasksStatsYesterday = resp.data.results;
        }
      )
    },
    onTaskDeleteConfirmed() {
      this.showTaskDeleteDialog = false;
      this.initTasks();
    },
    onTaskSaved() {
      this.showTaskDialog = false;
      this.initTasks();
    },
    exportInExcel() {
      this.excelLoading = true
      this.value = 0
      this.$taskService.exportExcel(this.listParams)
        .then(resp => {
          let now = moment().format('DD MM YYYY hh:mm:ss')
          FileSaver.saveAs(resp.data, `RiepilogoOre-${now}.xlsx`)
          this.excelLoading = false
        })
    }
  }
}
</script>
<style>
.my_class td{
  font-size: 12px!important;
  height: 1!important;
}
</style>
