var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.details,"itemsPerPage":_vm.itemsPerPage,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.show_expiration_alert",fn:function(ref){
var item = ref.item;
return [(item.show_expiration_alert)?_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.snackbar = true}}},[_c('v-icon',{attrs:{"small":"","color":!item.expires_tomorrow ? 'red' : 'grey'}},[_vm._v(" mdi-bell-badge-outline ")])],1),_c('v-snackbar',{attrs:{"timeout":-1,"absolute":"","bottom":"","color":"primary","left":"","text":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}],null,true),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" il Prodotto ha un'anomalia! ")])],1):_vm._e()]}},{key:"item.delivery_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.delivery_date))+" ")]}},{key:"item.count",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.operator_name",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"custom",attrs:{"dense":"","menu-props":{ top: true, offsetY: true },"items":_vm.operatori,"item-value":"id","item-text":"full_name","multiple":"","persistent-hint":""},on:{"input":function($event){return _vm.salvaOperatori(item)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","x-small":"","color":"primary"}},[_vm._v(_vm._s(item.last_name))])]}}],null,true),model:{value:(item.operators),callback:function ($$v) {_vm.$set(item, "operators", $$v)},expression:"item.operators"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"custom",attrs:{"chips":"","dense":"","menu-props":{ top: true, offsetY: true },"items":_vm.stati,"item-text":"text","item-value":"value"},on:{"input":function($event){return _vm.salvaStato(item)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","x-small":"","color":_vm.getColor(item.value)}},[_vm._v(_vm._s(item.text))])]}}],null,true),model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.notified)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.loading[index],"text":"","small":""},on:{"click":function($event){return _vm.sendEmail(item, _vm.loading[index] = true)}}},tooltip),[_c('v-icon',{staticStyle:{"color":"darkolivegreen"},attrs:{"small":""}},[_vm._v("mdi-email-check-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Notifica Inviata")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.loading[index],"text":"","small":""},on:{"click":function($event){return _vm.sendEmail(item, _vm.loading[index] = true)}}},tooltip),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-email-fast")])],1)]}}],null,true)},[_c('span',[_vm._v("Invia Notifica")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }