import axios from 'axios';
import authHeader from '/src/services/auth.header';
const API_URL = process.env.VUE_APP_API_URL

class UserService {
    login(username, password) {
        return axios
            .post(API_URL + 'login/', {
                username: username,
                password: password
            })
    }

    self() {
        return axios.get(`${API_URL}user/self/`, {headers: authHeader()})
    }

    logout() {
        console.log('prima chiamata')
        return axios.post(`${API_URL}logout/`, {headers: authHeader()})
    }

    list(params) {
        return axios.get(`${API_URL}user/`, {
            headers: authHeader(),
            params: params
        })
    }
}

export default new UserService();
