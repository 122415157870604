import axios from 'axios';
import authHeader from '/src/services/auth.header';
const API_URL = process.env.VUE_APP_API_URL

class ProjectService {
    get(id, params = {}) {
        return axios.get(
             `${API_URL}project/${id}/`,
            {headers: authHeader(), params: params})
    }

    patch(id, data = {}) {
        return axios.patch(
            `${API_URL}project/${id}/`,
            data,
            {headers: authHeader()}
        )
    }

    post(data = {}) {
        return axios.post(
            `${API_URL}project/`,
            data,
            {headers: authHeader()}
        )
    }

    list(params) {
        return axios.get(
            `${API_URL}project/`,
            {headers: authHeader(), params: params}
        )
    }

    delete(id) {
        return axios.delete(
            `${API_URL}project/${id}/`,
            {headers: authHeader()}
        )
    }

    // stats() {
    //     return axios.get(
    //         'http://127.0.0.1:8000/api/project/stats/',
    //         {headers: authHeader()}
    //     )
    // }

    clone(projectId, data = {}) {
        return axios.post(
            `${API_URL}project/${projectId}/clone/`,
            data,
            {headers: authHeader()}
        )
    }

    save_activities(projectId, activities) {
        return axios.post(
            `${API_URL}project/${projectId}/save_activities/`,
            {activities: activities},
            {headers: authHeader()}
        )
    }

    activities_menu(projectId) {
        return axios.get(
            `${API_URL}project/${projectId}/activities_menu/`,
            {headers: authHeader()}
        )
    }

  clone_activities(projectId) {
    return axios.get(
      `${API_URL}project/${projectId}/clone_activities/`,
      {headers: authHeader()}
    )
  }

    sendNotifications(payload) {
        return axios.post(
            `${API_URL}send_email`,
            payload,
            { headers: authHeader()}
        )
    }
}

export default new ProjectService();
