<template>
    <div>
      <template>
          <v-data-table
              v-if="customers"
              :headers="headers"
              :items="customers"
              :items-per-page="-1"
              class="elevation-1"
              dense
          >
              <template v-slot:top>
                  <v-col>
                      <v-toolbar flat>
                          <v-toolbar-title>Insegne</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" dark class="mb-2" @click="addCustomer">
                              Aggiungi Insegna
                          </v-btn>
                      </v-toolbar>
                  </v-col>
              </template>
              <template #item.secondary="{ item }">
                <v-checkbox
                    v-model="item.secondary"
                    color="secondary"
                    hide-details
                    @click="onSecondaryClick(item)"
                    class="text-center"
                ></v-checkbox>
              </template>
              <template #item.actions="{ item }">
                  <v-icon
                      title="Modifica"
                      small class="ml-2" @click="editCustomer(item)">
                      mdi-pencil
                  </v-icon>
                  <v-icon
                      small class="ml-2" title="Elimina"
                      @click="deleteCustomer(item)">
                      mdi-delete
                  </v-icon>
              </template>
          </v-data-table>
      </template>
      <!-- Modali -->
      <customer-dialog
      v-if="showCustomerDialog"
      v-model="showCustomerDialog"
      :customer-id="selectedCustomerId"
      @customerSaved="onCustomerSaved"/>
      <customer-delete-dialog
      v-if="showCustomerDeleteDialog"
      v-model="showCustomerDeleteDialog"
      :customer-id="customerIdToDelete"
      @confirmed="loadCustomers()"/>
    </div>
</template>

<script>
import {format, parseISO} from "date-fns";
import CustomerDeleteDialog from "./CustomerDeleteDialog.vue";
import CustomerDialog from "./CustomerDialog.vue";

export default {
    components: {
        CustomerDeleteDialog,
        CustomerDialog
    },
    data: () => ({
        customers: null,
        showCustomerDialog: false,
        selectedCustomerId: null,
        customerIdToDelete: false,
        showCustomerDeleteDialog: false,
    }),
    computed: {
        headers() {
            return [
                {value: 'name', text: 'Nome'},
                {value: 'address', text: 'Indirizzo'},
                {value: 'telephone', text: 'Telefono'},
                {value: 'email', text: 'Email'},
                {value: 'secondary', text: 'Secondario'},
                {value: 'actions', text: 'Azioni', sortable: false}
            ]
        }
    },
    mounted() {
        this.ensureUserInStore().then(() => {
            if (this.$store.state.user.role === 'MANAGEMENT_CONTROL') {
                this.$router.push({name: 'tasks'});
            }
        })
        this.loadCustomers()
    },
  methods: {
    addCustomer() {
      this.selectedCustomerId = null
      this.showCustomerDialog = true
    },
    deleteCustomer(item) {
      this.customerIdToDelete = item.id
      this.showCustomerDeleteDialog = true
    },
    editCustomer(item) {
      this.selectedCustomerId = item.id
      this.showCustomerDialog = true
    },
    formatDate(isoDate) {
      return isoDate ? format(parseISO(isoDate), 'dd/MM/yyyy') : ''
    },
    loadCustomers() {
      this.$customerService.list().then(
          response => {
            this.customers = response.data;
          }
      )
    },
    onCustomerSaved() {
      this.showCustomerDialog = false
      this.loadCustomers()
    },
    onSecondaryClick(item) {
      const data = {
        secondary: item.secondary
      }
      this.$customerService.patch(item.id, data)
          .then(() => {
            this.loadCustomers()
          })
          .catch(xhr => {
            this.$root.$emit('renderError', xhr)
          })
    },
    ensureUserInStore() {
      if (!this.$store.state.user) {
        return this.$store.dispatch('setCurrentUser');
      } else {
        return Promise.resolve();
      }
    }
  }
}
</script>
