<template>
    <div>
        <v-data-table
            :headers="activitiesHeaders"
            :items="project.activities_hours_by_operator"
            item-key="name"
            show-expand
            light
            class="elevation-2 mt-2"
            hide-default-footer
            dense
            :items-per-page="20"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>
                        Attività nel progetto: {{ project.activities_hours_by_operator.length }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>
            <template #item.alert="{ item }">
              <v-btn v-if="item.alert" icon>
                <v-icon small >
                  mdi-bell-badge-outline
                </v-icon>
              </v-btn>
            </template>
            <template #expanded-item="{ headers, item }">
                <detail-project-activities-product
                  :products="project.products_by_activity[item.id] || []"
                  :operators="operators"
                  @taskSaved="$emit('taskSaved')" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import DetailProjectActivitiesProduct from "./DetailProjectActivitiesProduct.vue"

export default {
    components: {DetailProjectActivitiesProduct},
    props: {
        project: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            expanded: [],
        };
    },
    mounted() {
      console.log(this.project)
    },
  computed: {
        activitiesHeaders() {
            const items = this.operators.reduce((array, item) => {
                array.push(
                    {text: item.full_name, value: `${item.id}`},
                )
                return array
            }, []);
            return [].concat(
                [
                  {text: 'Alert', align: 'start', sortable: false, value: 'alert'},
                  {text: 'Attività', align: 'start', sortable: false, value: 'name'},
                ],
                items,
                [{text: '', value: 'data-table-expand'}]
            );
        },
        operators() {
            return this.project.operators;
        }
    }
}
</script>
<style>
tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}
</style>
