import axios from 'axios';
import authHeader from '/src/services/auth.header';

const API_URL = process.env.VUE_APP_API_URL

class ProviderService {
    get(id, params = {}) {
        return axios.get(
            `${API_URL}provider/${id}/`,
            {headers: authHeader(), params: params})
    }

    patch(id, data = {}) {
        return axios.patch(
            `${API_URL}provider/${id}/`,
            data,
            {headers: authHeader()}
        )
    }

    post(data = {}) {
        return axios.post(
            `${API_URL}provider/`,
            data,
            {headers: authHeader()}
        )
    }

    list(params) {
        return axios.get(
            `${API_URL}provider/`,
            {headers: authHeader(), params: params}
        )
    }

    delete(id) {
        return axios.delete(
            `${API_URL}provider/${id}/`,
            {headers: authHeader()}
        )
    }

    getEmails(id) {
        return axios.get(
            `${API_URL}provider_emails/?provider=${id}`,
            {headers: authHeader()}
        )
    }
}

export default new ProviderService();
