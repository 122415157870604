<template>
  <div class="dashboard">
    <h1 class="text-sm-subtitle-1 grey--text">Dashboard</h1>
      Scegliere Progetto da Clonare:
      <v-container class="my-5">
          <v-row>
              <template>
                  <v-card
                      class="mx-auto"
                      max-width="344"
                      hover
                  >
                      <v-img
                          src="static/image/logo.png"
                          width="250"
                          height="120"
                      ></v-img>

                      <v-card-title>
                          Progetto Kit Web
                      </v-card-title>

                      <v-card-subtitle>
                          86 ore
                      </v-card-subtitle>

                      <v-card-actions>
                          <v-btn
                              color="orange lighten-2"
                              text
                          >
                              Dettagli
                          </v-btn>
                          <v-btn
                              color="green"
                              text
                          >
                              Clona
                          </v-btn>

                          <v-spacer></v-spacer>

                          <v-btn
                              icon
                              @click="show = !show"
                          >
                              <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                          </v-btn>
                      </v-card-actions>

                      <v-expand-transition>
                          <div v-show="show">
                              <v-divider></v-divider>

                              <v-card-text>
                                  <v-list shaped>
                                      <v-list-item-group multiple>
                                          <v-list-item
                                              active-class="deep-purple--text text--accent-4"
                                          >
                                              Impaginazione
                                              <v-spacer></v-spacer>
                                              <v-list-item-action>
                                                  <v-checkbox
                                                      color="deep-purple accent-4"
                                                  ></v-checkbox>
                                              </v-list-item-action>
                                          </v-list-item>
                                          <v-list-item>
                                              Correzioni
                                              <v-spacer></v-spacer>
                                              <v-list-item-action>
                                                  <v-checkbox
                                                      color="deep-purple accent-4"
                                                  ></v-checkbox>
                                              </v-list-item-action>
                                          </v-list-item>
                                          <v-list-item>
                                              Copertina
                                              <v-spacer></v-spacer>
                                              <v-list-item-action>
                                                  <v-checkbox
                                                      color="deep-purple accent-4"
                                                  ></v-checkbox>
                                              </v-list-item-action>
                                          </v-list-item>
                                          <v-list-item>
                                              Immagini
                                              <v-spacer></v-spacer>
                                              <v-list-item-action>
                                                  <v-checkbox
                                                      color="deep-purple accent-4"
                                                  ></v-checkbox>
                                              </v-list-item-action>
                                          </v-list-item>
                                          <v-list-item>
                                              Co-Marketing
                                              <v-spacer></v-spacer>
                                              <v-list-item-action>
                                                  <v-checkbox
                                                      color="deep-purple accent-4"
                                                  ></v-checkbox>
                                              </v-list-item-action>
                                          </v-list-item>
                                      </v-list-item-group>

                                  </v-list>
                              </v-card-text>
                          </div>
                      </v-expand-transition>
                  </v-card>
                  <v-card
                      class="mx-auto"
                      max-width="344"
                      hover
                  >
                      <v-img
                          src="static/image/logo.png"
                          width="250"
                          height="120"
                      ></v-img>

                      <v-card-title>
                          Progetto Campagna di Natale
                      </v-card-title>

                      <v-card-subtitle>
                          126 ore
                      </v-card-subtitle>

                      <v-card-actions>
                          <v-btn
                              color="orange lighten-2"
                              text
                          >
                              Dettagli
                          </v-btn>
                          <v-btn
                              color="green"
                              text
                          >
                              Clona
                          </v-btn>

                          <v-spacer></v-spacer>

                          <v-btn
                              icon
                              @click="show = !show"
                          >
                              <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                          </v-btn>
                      </v-card-actions>

                      <v-expand-transition>
                          <div v-show="show">
                              <v-divider></v-divider>

                              <v-card-text>
                                  <v-list shaped>
                                      <v-list-item-group multiple>
                                          <v-list-item
                                              active-class="deep-purple--text text--accent-4"
                                          >
                                              Impaginazione
                                              <v-spacer></v-spacer>
                                              <v-list-item-action>
                                                  <v-checkbox
                                                      color="deep-purple accent-4"
                                                  ></v-checkbox>
                                              </v-list-item-action>
                                          </v-list-item>
                                          <v-list-item>
                                              Correzioni
                                              <v-spacer></v-spacer>
                                              <v-list-item-action>
                                                  <v-checkbox
                                                      color="deep-purple accent-4"
                                                  ></v-checkbox>
                                              </v-list-item-action>
                                          </v-list-item>
                                          <v-list-item>
                                              Copertina
                                              <v-spacer></v-spacer>
                                              <v-list-item-action>
                                                  <v-checkbox
                                                      color="deep-purple accent-4"
                                                  ></v-checkbox>
                                              </v-list-item-action>
                                          </v-list-item>
                                          <v-list-item>
                                              Immagini
                                              <v-spacer></v-spacer>
                                              <v-list-item-action>
                                                  <v-checkbox
                                                      color="deep-purple accent-4"
                                                  ></v-checkbox>
                                              </v-list-item-action>
                                          </v-list-item>
                                          <v-list-item>
                                              Co-Marketing
                                              <v-spacer></v-spacer>
                                              <v-list-item-action>
                                                  <v-checkbox
                                                      color="deep-purple accent-4"
                                                  ></v-checkbox>
                                              </v-list-item-action>
                                          </v-list-item>
                                      </v-list-item-group>

                                  </v-list>
                              </v-card-text>
                          </div>
                      </v-expand-transition>
                  </v-card>
                  <v-card
                      class="mx-auto"
                      max-width="344"
                      hover
                  >
                      <v-img
                          src="static/image/logo.png"
                          width="250"
                          height="120"
                      ></v-img>

                      <v-card-title>
                          Progetto Campagna
                      </v-card-title>

                      <v-card-subtitle>
                          210 ore
                      </v-card-subtitle>

                      <v-card-actions>
                          <v-btn
                              color="orange lighten-2"
                              text
                          >
                              Dettagli
                          </v-btn>
                          <v-btn
                              color="green"
                              text
                          >
                              Clona
                          </v-btn>

                          <v-spacer></v-spacer>

                          <v-btn
                              icon
                              @click="show = !show"
                          >
                              <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                          </v-btn>
                      </v-card-actions>

                      <v-expand-transition>
                          <div v-show="show">
                              <v-divider></v-divider>

                              <v-card-text>
                              <v-list shaped>
                                  <v-list-item-group multiple>
                                      <v-list-item
                                          active-class="deep-purple--text text--accent-4"
                                      >
                                          Impaginazione
                                          <v-spacer></v-spacer>
                                          <v-list-item-action>
                                              <v-checkbox
                                                  color="deep-purple accent-4"
                                              ></v-checkbox>
                                          </v-list-item-action>
                                      </v-list-item>
                                      <v-list-item>
                                          Correzioni
                                          <v-spacer></v-spacer>
                                          <v-list-item-action>
                                              <v-checkbox
                                                  color="deep-purple accent-4"
                                              ></v-checkbox>
                                          </v-list-item-action>
                                      </v-list-item>
                                      <v-list-item>
                                          Copertina
                                          <v-spacer></v-spacer>
                                          <v-list-item-action>
                                              <v-checkbox
                                                  color="deep-purple accent-4"
                                              ></v-checkbox>
                                          </v-list-item-action>
                                      </v-list-item>
                                      <v-list-item>
                                          Immagini
                                          <v-spacer></v-spacer>
                                          <v-list-item-action>
                                              <v-checkbox
                                                  color="deep-purple accent-4"
                                              ></v-checkbox>
                                          </v-list-item-action>
                                      </v-list-item>
                                      <v-list-item>
                                          Co-Marketing
                                          <v-spacer></v-spacer>
                                          <v-list-item-action>
                                              <v-checkbox
                                                  color="deep-purple accent-4"
                                              ></v-checkbox>
                                          </v-list-item-action>
                                      </v-list-item>
                                  </v-list-item-group>

                              </v-list>
                              </v-card-text>
                          </div>
                      </v-expand-transition>
                  </v-card>
              </template>
          </v-row>
      </v-container>
  </div>
</template>

<script>

  export default {
    name: 'Dashboard',
    data() {
      return {
          show: false
      }
    },
    created() {
        this.$router.push({name: 'projects'});
    },
  }
</script>


