import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'

// Services
import ActivityService from './services/activity.service.js'
import CustomerService from './services/customer.service.js'
import OrganizationService from './services/organization.service.js'
import ProductKindService from './services/productKind.service.js'
import ProductService from './services/product.service.js'
import ProjectService from './services/project.service.js'
import ProviderService from './services/provider.service.js'
import SubActivityService from './services/sub_activity.service.js'
import UserService from './services/user.service.js'
import TaskService from './services/task.service.js'

// Plugins
import 'dtoaster/dist/dtoaster.css'
import DToaster from 'dtoaster'
import ToasterPresets from './toast_presets.json' //Your predefined toasts presets (optionally)
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import VueCookies from 'vue-cookies'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
// Vuetify's CSS styles
import 'vuetify/dist/vuetify.min.css'
Vue.use(DToaster, {
    presets: ToasterPresets,
    position: 'top-right',
    containerOffset: '45px'
})
Vue.use(VueCookies)
Vue.config.productionTip = false
Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify, // same as "vuetify: vuetify"
    // optional, default to 'md' (default vuetify icons before v2.0.0)
    iconsGroup: 'mdi'
})
Vue.use(Vuelidate)
Vue.$cookies.config('7d')
// API
Vue.prototype.$activityService = ActivityService;
Vue.prototype.$customerService = CustomerService;
Vue.prototype.$organizationService = OrganizationService;
Vue.prototype.$productKindService = ProductKindService;
Vue.prototype.$productService = ProductService;
Vue.prototype.$projectService = ProjectService;
Vue.prototype.$providerService = ProviderService;
Vue.prototype.$subActivityService = SubActivityService;
Vue.prototype.$userService = UserService;
Vue.prototype.$taskService = TaskService;

// Icons
Vue.prototype.$icons = {
    'activity': 'mdi-application-outline',
    'product': 'mdi-application-cog-outline',
    'sub_activity': 'mdi-application-parentheses-outline',
    'task': 'mdi-clock-time-eight',
    'backup': 'mdi-database-clock-outline',
};

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
