<template>
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-progress-circular
                  v-show="progress"
                  indeterminate
                  color="white"
                ></v-progress-circular>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container>

                <form v-if="formLoaded" ref="form" class="mt-16">
                    <v-row>
                        <v-col cols="6">
                            <if65-date-picker
                                v-model="form.task_date"
                                ref="task_date"
                                :required="true"
                                :disabled="isEdit"
                                label="Data intervento"/>
                        </v-col>
                        <v-col cols="6">
                            <v-input
                              v-model="form.sub_activity"
                              disabled
                            ></v-input>
                            <if65-autocomplete
                                v-if="product || taskProductId"
                                ref="sub_activity"
                                v-model="form.sub_activity"
                                :api="$subActivityService.list"
                                :extra-params="{product: productId}"
                                :required="true"
                                label="Sotto attività"
                                @progress="finishGet" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <label>Seleziona Minuti/Ore Lavorate: <b>{{ this.form.minutes }}</b> minuti /
                                <b>{{ form.minutes / 60 }}</b> ore </label>
                            <v-slider
                                v-model="form.minutes"
                                step="30"
                                max="840"
                                thumb-label
                            ></v-slider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-textarea
                            outlined
                            label="Descrizione anomalia:"
                            v-model="form.description"
                        ></v-textarea>
                    </v-row>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" @click.prevent="saveTask">Salva</v-btn>
                    </v-row>
                </form>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import If65Autocomplete from "../../components/widgets/If65Autocomplete.vue";
import If65DatePicker from "../../components/widgets/If65DatePicker.vue";

export default {
    components: {If65Autocomplete, If65DatePicker},
    props: {
        taskId: {
            default: null
        },
        product: {
            default: null
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            show: false,
            form: {
                description: '',
                task_date: null,
                sub_activity: null,
                minutes: 0
            },
            formLoaded: true,
            progress: true,
            taskProductId: null,
        }
    },
    computed: {
        isEdit() {
            return this.taskId !== null;
        },
        title() {
            return 'Descrivi il tuo intervento'
        },
        productId() {
          return this.product ? this.product.id : this.taskProductId
        }
    },
    mounted() {
            if (this.taskId) {
              this.formLoaded = false
              this.$taskService.get(this.taskId).then(
                response => {
                  this.form.description = response.data.description;
                  this.form.task_date = response.data.task_date;
                  this.form.sub_activity = response.data.sub_activity;
                  this.form.minutes = response.data.minutes;
                  this.taskProductId = response.data.product;
                }
              ).finally(() => {
                this.formLoaded = true
              })
            }
    },
    watch: {
        value: {
            handler() {
                this.show = this.value
            },
            immediate: true
        },
        show() {
            this.$emit('input', this.show)
        }
    },
    methods: {
        // allowedDates(date) {
        //     if ( this.$store.state.user.role !== "ADMIN") {
        //       return isToday(parseISO(date)) || isYesterday(parseISO(date));
        //     } return true
        // },
        formIsValid() {
            this.$refs['task_date'].$v.$touch()
            this.$refs['sub_activity'].$v.$touch()
            return (
                !this.$refs['task_date'].$v.$invalid &&
                !this.$refs['sub_activity'].$v.$invalid
            )
        },
        saveTask() {
            if (this.formIsValid()) {
                const saveApi = (
                    this.isEdit ? this.$taskService.patch(this.taskId, this.form) : this.$taskService.post(this.form)
                );
                saveApi.then(
                    () => {
                        this.$emit('taskSaved')
                        this.$dtoast.pop({
                            preset: "success",
                            content: 'Task salvato',
                        })
                    }
                ).catch(
                    (xhr) => {
                        this.$dtoast.pop({
                            preset: "error",
                            content: xhr.response.data.non_field_errors.join(', ')
                        })
                    }
                )
            }
        },
        finishGet() {
          this.progress = false
        }
    }
}
</script>
