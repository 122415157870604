var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{attrs:{"colspan":_vm.colSpan}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.productHeaders,"items":_vm.products,"dense":"","hide-default-footer":"","items-per-page":20},scopedSlots:_vm._u([{key:"item.show_alert",fn:function(ref){
var item = ref.item;
return [(item.show_alert.length > 0)?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-bell-badge-outline ")])],1):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.status)?_c('v-select',{attrs:{"items":_vm.status,"item-value":"value","item-text":"text","dense":""},on:{"change":function($event){return _vm.patchProductStatus(item)}},model:{value:(item.status_value),callback:function ($$v) {_vm.$set(item, "status_value", $$v)},expression:"item.status_value"}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(item.subactivities > 0)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.canInsertTask(item))?_c('v-btn',_vm._b({attrs:{"v-on":on,"color":"primary","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openTaskDialog(item)}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-clock-time-eight-outline")])],1):_vm._e()]}}:null],null,true)},[_c('span',[_vm._v("Aggiungi Ore")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.canInsertTask(item))?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary ml-2","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openTaskDetails(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-card-account-details-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Guarda i Dettagli dell'attività")])]),(_vm.showTaskDialog)?_c('task-dialog',{attrs:{"product":_vm.product},on:{"taskSaved":_vm.onTaskSaved},model:{value:(_vm.showTaskDialog),callback:function ($$v) {_vm.showTaskDialog=$$v},expression:"showTaskDialog"}}):_vm._e()]}},{key:"item.provider",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","small":""},on:{"click":function($event){return _vm.showProvider(item)}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item.provider))])]}}],null,true)},[_c('span',[_vm._v("Guarda i Dettagli del Fornitore")])])]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.end_date))+" ")]}}])}),(_vm.showTaskDetails)?_c('v-card',{staticClass:"pb-3 background mt-5",attrs:{"elevation":"0"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-card-title',{staticStyle:{"color":"#455A64"}},[_c('h5',{staticStyle:{"text-decoration":"underline"}},[_vm._v("Cod. Progetto:")])]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.details.project_code))])],1)]}}],null,false,349952272)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-card-title',{staticStyle:{"color":"#455A64"}},[_c('h5',{staticStyle:{"text-decoration":"underline"}},[_vm._v("N° d'ordine:")])]),(_vm.details.order_number)?_c('v-card-subtitle',[_vm._v(_vm._s(_vm.details.order_number))]):_c('v-card-subtitle',[_vm._v("-- no-data --")])],1)]}}],null,false,4054085498)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-card-title',{staticStyle:{"color":"#455A64"}},[_c('h5',{staticStyle:{"text-decoration":"underline"}},[_vm._v("Descrizione:")])]),(_vm.details.description)?_c('v-card-subtitle',{domProps:{"innerHTML":_vm._s(_vm.details.description)}}):_c('v-card-subtitle',[_vm._v("-- no-data --")])],1)]}}],null,false,3788143076)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-card-title',{staticStyle:{"color":"#455A64"}},[_c('h5',{staticStyle:{"text-decoration":"underline"}},[_vm._v("Data di Consegna:")])]),(_vm.details.delivery_date)?_c('v-card-subtitle',[_vm._v(_vm._s(_vm.details.delivery_date))]):_c('v-card-subtitle',[_vm._v("-- no-data --")])],1)]}}],null,false,1119526237)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-card-title',{staticStyle:{"color":"#455A64"}},[_c('h5',{staticStyle:{"text-decoration":"underline"}},[_vm._v("Allegati:")])]),(_vm.details.note_attachment)?_c('v-card-subtitle',[_c('a',{attrs:{"href":_vm.details.note_attachment,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.details.note_name[0])+" ")])]):_vm._e()],1)]}}],null,false,4153297726)})],1)],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.showProviderDetails),callback:function ($$v) {_vm.showProviderDetails=$$v},expression:"showProviderDetails"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Dettagli Fornitore: "+_vm._s(this.provider.organization_name))]),_c('v-card-text',[_c('div',{staticClass:"pa-3"},[_c('v-row',[_c('v-col',[_vm._v(" Descrizione Invio File: "),_c('b',[_vm._v(_vm._s(this.provider.send_file_description))]),_c('hr')])],1),_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center"}},[_c('b',[_vm._v("DETTAGLI:")])])],1),_c('v-row',[_c('v-col',_vm._l((_vm.emails),function(contatto,index){return _c('div',{key:index,staticClass:"mt-2"},[_vm._v(" Email: "),_c('b',[_vm._v(_vm._s(contatto.email))]),_c('span',{staticClass:"float-end"},[_vm._v("Telefono: "),_c('b',[_vm._v(_vm._s(contatto.phone))])]),_c('hr')])}),0)],1)],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.showProviderDetails = false}}},[_vm._v(" Chiudi ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }