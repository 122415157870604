<template>
    <div v-if="project">
        <detail-project-header :project="project"></detail-project-header>
        <detail-project-activities :project="project" @taskSaved="initProject()"/>
        <v-dialog
            v-model="dialog"
            transition="dialog-bottom-transition"
            width="500"
            :retain-focus="false"
        >
            <template v-slot:default="dialog">
                <v-card>
                    <v-toolbar
                        color="primary"
                        dark
                    >Aggiungi Ore/Task al 60 x 100 / Kit Stampa
                    </v-toolbar>

                    <v-card-text>
                        <v-form class="mt-3">
                            <v-row>
                                <v-col>
                                    <if65-date-picker value="" label="Data Progetto"></if65-date-picker>
                                </v-col>
                                <v-col>
                                    <v-select
                                        :item-text="product.prodotto"
                                        label="Seleziona Prodotto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <label>Seleziona Minuti/Ore Lavorate: <b>{{ minutiLavorati }}</b> minuti /
                                        <b>{{ minutiLavorati / 60 }}</b> ore </label>
                                    <v-slider
                                        v-model="minutiLavorati"
                                        step="30"
                                        max="480"
                                        thumb-label
                                    ></v-slider>

                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea
                                        outlined
                                        name="input-7-4"
                                        label="NOTE:"
                                        value=""
                                    ></v-textarea>
                                </v-col>

                            </v-row>
                        </v-form>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            @click="dialog = false"
                        >
                            INSERISCI
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </div>
</template>

<script>
import If65DatePicker from "../../components/widgets/If65DatePicker";
import DetailProjectHeader from "./DetailProjectHeader.vue";
import DetailProjectActivities from "./DetailProjectActivities.vue";

export default {
    components: {
        If65DatePicker,
        DetailProjectHeader,
        DetailProjectActivities
    },
    data() {
        return {
            project: null,
            dialog: false,
        }
    },
    mounted() {
        this.initProject();
    },
    computed: {
        projectId() {
            return this.$route.params.projectId
        }
    },
    methods: {
        initProject() {
            this.$projectService.get(this.projectId, {detail: ''}).then(
                resp => {
                    this.project = resp.data
                }
            ).catch(
                () => {
                    this.$router.push({name: 'projects'});
                }
            )
        }
    }
}
</script>

<style scoped>
.ADMIN {
    color: red;
}

.GRAPHIC_OPERATOR {
    color: #1976d2;
}

td {
    border: none;
    background-color: #455A64;
}

tr:nth-of-type(4n+3) {
    background-color: rgba(0, 0, 0, .05);
}
</style>

