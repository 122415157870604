<template>
    <div>
        <v-col><h1 class="mt-6 mt-3">Modifica progetto {{ projectName }}</h1></v-col>
        <v-col>
            <v-stepper v-model="step" vertical elevation="6">
                <v-stepper-step :complete="step > 1" step="1">
                    Dati progetto<small>Configurazione base progetto</small>
                </v-stepper-step>
                <v-stepper-content step="1">
                    <step1
                        v-if="step === 1"
                        :project-id="projectId"
                        :clone="clone"
                        @stepChanged="onStepChange"/>
                </v-stepper-content>

                <v-stepper-step :complete="step > 2" step="2">
                    Attività<small>Selezione attività</small>
                </v-stepper-step>
                <v-stepper-content step="2">
                    <step2 v-if="step === 2" :project-id="projectId" @stepChanged="onStepChange"/>
                </v-stepper-content>

                <v-stepper-step :complete="step > 3" step="3">
                    Prodotti<small>Configurazione Prodotti e sotto attività</small>
                </v-stepper-step>
                <v-stepper-content step="3">
                    <step3 v-if="step === 3" :project-id="projectId"
                           @stepChanged="onStepChange" @productChange="onProductChange"/>
                </v-stepper-content>
            </v-stepper>
        </v-col>
    </div>
</template>

<script>
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import ProjectService from "../../services/project.service";

export default {
    components: {
        Step1,
        Step2,
        Step3,
    },
    props: {
        initialProjectId: {
            default: null
        },
        clone: {
            default: false
        },
    },
    data() {
        return {
            projectId: this.initialProjectId,
            projectName: '',
            step: 1,
        }
    },
    computed: {
        initialStep() {
            return (!this.projectId || this.clone) && this.$store.state.user.role === 'ADMIN' ? 1 : 3;
        }
    },
    mounted() {
        this.step = this.initialStep;
        if (this.projectId) {
            ProjectService.get(this.projectId).then(
                resp => {
                    this.projectName = resp.data.name
                }
            )
        }

    },
    methods: {
        onProductChange() {
            // this.$refs['tasks-project'].initProject();
            // era usato per ricaricare il progetto nel componente TaskProject.vue
        },
        onStepChange(step, stepChangeData) {
            if (this.$store.state.user.role !== 'ADMIN') {
                this.step = 3;
            } else {
                if (this.step === 1 && step === 2) {
                    this.projectId = stepChangeData.projectId
                }
                if (step === 4) {
                    this.$emit('completed')
                } else {
                    this.step = step
                }
            }
        },
    }
}
</script>
