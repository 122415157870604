import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
          light: {
              if65Blue: '#414141',
              italbrix: '#ba1d28',
              if65Silver: '#b3b6b7',
              background: '#f1f1f1',
          },
      }
    },
    options: {
        customProperties: true
    },
    icons: {
        iconfont: 'mdi',
        values : {
            dashboard: 'mdi-view-dashboard',
            project: 'mdi-projector-screen-outline',
            exit: 'mdi-exit-to-app',
            team: 'mdi-account-group'
        }
    }
});
