<template>
    <div>
        <v-file-input
            :disabled="disabled"
            :label="label"
            v-model="model"
            chips
            truncate-length="15"
            @change="onChange"
        ></v-file-input>
        <small v-if="initialUrl">
            File attualmente caricato: <a :href="initialUrl">{{ initialFileName }}</a>
        </small>
    </div>
</template>

<script>

export default {
    props: {
        label: {
            type: String,
            default: 'Carica file'
        },
        disabled: {
          type: Boolean,
          default: false
        },
        initialUrl: {
            default: null
        }
    },
    data() {
        return {
            model: null
        }
    },
    computed: {
        initialFileName() {
            return this.initialUrl.split('/').pop();
        },
        initialFooFile() {
            return this.initialUrl ? new File(["foo"], this.initialFileName) : null;
        },
    },
    mounted() {
        if (this.initialUrl) {
            this.model = this.initialFooFile;
        }
    },
    methods: {
        onChange(value) {
            this.$emit('change', value)
        }
    }
}
</script>
