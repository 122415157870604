<template>
    <form ref="form">
        <step-base>
            <template #step-content>
                <v-row>
                    <v-col>
                        <v-text-field
                            :disabled="['PURCHASE_OPERATOR'].includes($store.state.user.role)"
                            v-model="form.name"
                            :error-messages="nameErrors"
                            label="Nome Prodotto"
                            required
                            @input="$v.form.name.$touch()"
                            @blur="$v.form.name.$touch()">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            :disabled="['PURCHASE_OPERATOR'].includes($store.state.user.role)"
                            v-model="form.custom_id"
                            :error-messages="customIdErrors"
                            label="ID custom"
                            required
                            @input="$v.form.custom_id.$touch()"
                            @blur="$v.form.custom_id.$touch()">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <if65-autocomplete
                            :disabled="['PURCHASE_OPERATOR'].includes($store.state.user.role)"
                            ref="customer_autocomplete"
                            v-model="form.customer"
                            :api="customerApiList"
                            :required="true"
                            label="Insegna"/>
                    </v-col>
                    <v-col>
                        <if65-autocomplete
                            :disabled="['PURCHASE_OPERATOR'].includes($store.state.user.role)"
                            ref="owner_autocomplete"
                            v-model="form.owner"
                            :api="ownerApiList"
                            :extraParams="{role: 'ADMIN'}"
                            label="Referente"
                            :required="true"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <if65-date-picker
                            :disabled="['PURCHASE_OPERATOR'].includes($store.state.user.role)"
                            v-model="form.data_promozione_dal"
                            label="Data inizio promozione"
                        />
                      <if65-date-picker
                        :disabled="['PURCHASE_OPERATOR'].includes($store.state.user.role)"
                        v-model="form.data_promozione_al"
                        label="Data fine promozione"/>
                      <v-text-field v-model="form.anno_riferimento" label="Anno di Riferimento"></v-text-field>
                    </v-col>
                    <v-col>
                      <if65-date-picker
                        :disabled="['PURCHASE_OPERATOR'].includes($store.state.user.role)"
                        v-model="form.data_sottocosto_dal"
                        label="Data inizio sottocosto"/>
                      <if65-date-picker
                        :disabled="['PURCHASE_OPERATOR'].includes($store.state.user.role)"
                        v-model="form.data_sottocosto_al"
                        label="Data fine sottocosto"/>
                    </v-col>
                </v-row>
            </template>
            <template #step-buttons>
                <v-btn color="primary" type="submit" @click.prevent="nextStep">Avanti</v-btn>
            </template>
        </step-base>
    </form>
</template>

<script>
import CustomerService from '../../services/customer.service.js'
import If65Autocomplete from '../widgets/If65Autocomplete.vue'
import ProjectService from "../../services/project.service.js";
import StepBase from "./StepBase.vue";
import UserService from '../../services/user.service.js'
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import If65DatePicker from "../widgets/If65DatePicker";
export default {
    components: {
      If65DatePicker,
      If65Autocomplete,
      StepBase,
    },
    mixins: [validationMixin],
    validations: {
        form: {
            name: {required},
            custom_id: {required},
            customer: {required},
        }
    },
    data() {
        return {
            form: {
                name: '',
                custom_id: '',
                owner: null,
                customer: null,
                anno_riferimento: null,
                data_promozione_dal: null,
                data_promozione_al: null,
                data_sottocosto_dal: null,
                data_sottocosto_al: null,
                start_date: null,
                end_date: null
            }
        }
    },
    props: {
        clone: {
            default: false
        },
        projectId: {
            default: null
        }
    },
    mounted() {
        this.initForm()
    },
    computed: {
        customerApiList() {
            return CustomerService.list
        },
        nameErrors() {
            const errors = [];
            if (this.$v.form.name.$dirty && !this.$v.form.name.required) {
                errors.push('Campo richiesto');
            }
            return errors;
        },
        customIdErrors() {
            const errors = [];
            if (this.$v.form.custom_id.$dirty && !this.$v.form.custom_id.required) {
                errors.push('Campo richiesto');
            }
            return errors;
        },
        ownerApiList() {
            return UserService.list
        }
    },
    methods: {
        initForm() {
            if (this.projectId) {
                ProjectService.get(this.projectId).then(
                    response => {
                        this.form.name = response.data.name
                        this.form.custom_id = response.data.custom_id
                        this.form.owner = response.data.owner
                        this.form.customer = response.data.customer
                        this.form.anno_riferimento = response.data.anno_riferimento
                        this.form.data_promozione_dal = response.data.data_promozione_dal
                        this.form.data_promozione_al = response.data.data_promozione_al
                        this.form.data_sottocosto_dal = response.data.data_sottocosto_dal
                        this.form.data_sottocosto_al = response.data.data_sottocosto_al
                    }
                )
            }
        },
        nextStep() {
            if (this.formIsValid()) {
                if (this.projectId) {
                    ProjectService.patch(this.projectId, this.form)
                        .then(
                            () => {
                                this.$emit('stepChanged', 2, {projectId: this.projectId})
                            }
                        )
                        .catch(
                            xhr => {
                                console.log(xhr.response)
                            }
                        )
                } else {
                    ProjectService.post(this.form)
                        .then(
                            xhr => {
                                this.$emit('stepChanged', 2, {projectId: xhr.data.id})
                            }
                        )
                        .catch(
                            xhr => {
                                console.log(xhr.response)
                            }
                        )
                }
            }
        },
        formIsValid() {
            this.$v.$touch()
            this.$refs['customer_autocomplete'].$v.$touch()
            this.$refs['owner_autocomplete'].$v.$touch()
            return (
                !this.$v.$invalid &&
                !this.$refs['customer_autocomplete'].$v.$invalid &&
                !this.$refs['owner_autocomplete'].$v.$invalid
            )
        }
    }
}
</script>
