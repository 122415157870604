<template>
    <v-dialog v-if="dialog" v-model="dialog" persistent max-width="600px">
        <v-card>
            <v-app-bar flat>
                <v-toolbar-title class="text-h6 pl-0">
                    Attenzione
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-app-bar>
            <v-card-text>
                <v-container>
                    <p>
                        Le seguenti sotto attività che stai eliminando hanno task collegati:
                    </p>
                    <ul>
                        <li v-for="sa in subActivitiesWithTask" :key="sa">
                            {{ sa }}
                        </li>
                    </ul>
                    <br>
                    <p>
                        se procedi verranno eliminati tutti task collegati
                    </p>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">annulla</v-btn>
                <v-btn v-if="showRestoreButton" color="blue darken-1" text @click="cancel">Ripristina</v-btn>
                <v-btn color="blue darken-1" text @click="confirm">Procedi</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    props: {
        showRestoreButton: {
            type: Boolean,
            default: false
        },
        subActivitiesWithTask: {
            type: Array,
            required: true
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialog: false,
        }
    },
    watch: {
        value: {
            handler() {
                this.dialog = this.value
            },
            immediate: true
        },
        dialog() {
            this.$emit('input', this.dialog)
        }
    },
    methods: {
        cancel() {
            this.dialog = false;
            this.$emit('canceled')
        },
        close() {
            this.dialog = false;
            this.$emit('closed')
        },
        confirm() {
            this.dialog = false;
            this.$emit('confirmed')
        }
    }
}
</script>
