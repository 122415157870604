<template>
    <v-dialog
        v-model="show"
        hide-overlay
        max-width="700px"
        transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>Gestione Insegne</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container>
                <form v-if="formLoaded" ref="form" class="ma-15">
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="form.name"
                                :error-messages="nameErrors"
                                label="Nome"
                                required
                                @input="$v.form.name.$touch()"
                                @blur="$v.form.name.$touch()">
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="form.address"
                                label="Indirizzo">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="form.telephone"
                                type="number"
                                label="Telefono">
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="form.email"
                                label="Email">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" @click.prevent="saveCustomer">Salva</v-btn>
                    </v-row>
                </form>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import {validationMixin} from "vuelidate";
import {email, minLength, required} from "vuelidate/lib/validators";

export default {
    components: {},
    mixins: [validationMixin],
    validations: {
        form: {
            name: {required},
            address: {minLength: minLength(6)},
            telephone: {minLength: minLength(6)},
            email: {email},
        }
    },
    props: {
        customerId: {
            default: null
        },
        clone: {
            default: false
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            show: false,
            form: {
                name: '',
                address: '',
                telephone: '',
                email: '',
            },
            formLoaded: false,
        }
    },
    computed: {
        isEdit() {
            return this.customerId !== null;
        },
        nameErrors() {
            const errors = [];
            if (this.$v.form.name.$dirty && !this.$v.form.name.required) {
                errors.push('Campo richiesto');
            }
            return errors;
        },
        addressErrors() {
            const errors = [];
            if (this.$v.form.address.$dirty) {
                if (!this.$v.form.address.minLength) {
                    errors.push('Inserisci un indirizzo valido');
                }
                if (!this.$v.form.address.required) {
                    errors.push('Campo richiesto');
                }
            }
            return errors;
        },
        telephoneErrors() {
            const errors = [];
            if (this.$v.form.telephone.$dirty) {
                if (!this.$v.form.telephone.minLength) {
                    errors.push('Inserisci un numero valido');
                }
                if (!this.$v.form.telephone.required) {
                    errors.push('Campo richiesto');
                }
            }
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (this.$v.form.email.$dirty) {
                if (!this.$v.form.email.required) {
                    errors.push('Campo richiesto');
                }
                if (!this.$v.form.email.email) {
                    errors.push('Inserisci un\'email valida');
                }
            }
            return errors;
        },
    },
    mounted() {
        if (this.customerId) {
            this.$customerService.get(this.customerId).then(
                response => {
                    this.form.name = response.data.name;
                    this.form.address = response.data.address;
                    this.form.telephone = response.data.telephone;
                    this.form.email = response.data.email;
                    this.formLoaded = true;
                }
            )
        } else {
            this.formLoaded = true;
        }
    },
    watch: {
        value: {
            handler() {
                this.show = this.value
            },
            immediate: true
        },
        show() {
            this.$emit('input', this.show)
        }
    },
    methods: {
        formIsValid() {
            this.$v.$touch()
            return !this.$v.$invalid
        },
        saveCustomer() {
            if (this.formIsValid()) {
                const saveApi = (
                    this.isEdit ? this.$customerService.patch(this.customerId, this.form) : this.$customerService.post(this.form)
                );
                saveApi.then(
                    () => {
                        this.$emit('customerSaved')
                        this.$dtoast.pop({
                            preset: "success",
                            content: 'Insegna salvata',
                        })
                    }
                ).catch(
                    (xhr) => {
                        this.$dtoast.pop({
                            preset: "error",
                            content: xhr.response.data.non_field_errors.join(', ')
                        })
                    }
                )
            }
        }
    }
}
</script>
