<template>
    <v-dialog
        v-model="show"
        hide-overlay
        max-width="700px"
        transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>Gestione Fornitore</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container>
                <form v-if="formLoaded" ref="form" class="ma-15">
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="form.name"
                                :error-messages="nameErrors"
                                label="Nome"
                                required
                                @input="$v.form.name.$touch()"
                                @blur="$v.form.name.$touch()">
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <if65-tag-selector
                                :multiple="false"
                                v-model="form.organization_name"
                                :items="organizations"
                                label="Azienda"/>
                        </v-col>
                    </v-row>
                    <div v-for="(contatto, index) in form.emails" :key="index">
                        <v-row>
                            <v-col cols="5">
                                <v-text-field
                                    v-model="contatto.email"
                                    label="Email"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col cols="5">
                                <v-text-field
                                    v-model="contatto.phone"
                                    label="Telefono">
                                </v-text-field>
                            </v-col>
                            <v-col v-if="index===0" cols="2" class="pa-5">
                                <v-btn text @click="addEmails()"><v-icon>mdi-plus</v-icon></v-btn>
                            </v-col>
                            <v-col v-else cols="2" class="pa-5">
                                <v-btn text @click="removeEmails(index)"><v-icon>mdi-minus</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="form.send_file_description"
                                :error-messages="sendFileDescriptionErrors"
                                label="Descrizione invio file"
                                required
                                @input="$v.form.send_file_description.$touch()"
                                @blur="$v.form.send_file_description.$touch()">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" @click.prevent="saveProvider">Salva</v-btn>
                    </v-row>
                </form>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import If65TagSelector from "../../components/widgets/If65TagSelector.vue";

export default {
    components: {If65TagSelector},
    mixins: [validationMixin],
    validations: {
        form: {
            name: {required},
            send_file_description: {required},
            // organization_name: {required},
        }
    },
    props: {
        providerId: {
            default: null
        },
        clone: {
            default: false
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            show: false,
            organizations: [],
            form: {
                name: '',
                send_file_description: '',
                organization_name: '',
                emails:[
                    {'email': '', 'phone': ''},
                ]
            },
            formLoaded: false,
            deletedEmail: [],
            addEmail: 0
        }
    },
    computed: {
        isEdit() {
            return this.providerId !== null;
        },
        nameErrors() {
            const errors = [];
            if (this.$v.form.name.$dirty && !this.$v.form.name.required) {
                errors.push('Campo richiesto');
            }
            return errors;
        },
        sendFileDescriptionErrors() {
            const errors = [];
            if (this.$v.form.send_file_description.$dirty) {
                if (!this.$v.form.send_file_description.required) {
                    errors.push('Campo richiesto');
                }
            }
            return errors;
        },
    },
    mounted() {
        this.initForm()
    },
    watch: {
        value: {
            handler() {
                this.show = this.value
            },
            immediate: true
        },
        show() {
            this.$emit('input', this.show)
        }
    },
    methods: {
        formIsValid() {
            this.$v.$touch()
            return !this.$v.$invalid
        },
        initForm() {
            this.formLoaded = false
            Promise.all(
                [
                    this.loadProvider(),
                    this.loadOrganizations()
                ]
            ).then(() => {
                this.formLoaded = true
            })
        },
        loadOrganizations() {
            return this.$organizationService.list().then(
                resp => {
                    this.organizations = resp.data.reduce((array, item) => {
                        array.push(item.name)
                        return array
                    }, [])
                }
            )
        },
        loadProvider() {
            if (this.providerId) {
                return this.$providerService.get(this.providerId).then(
                    response => {
                        this.form.name = response.data.name;
                        this.form.send_file_description = response.data.send_file_description;
                        this.form.organization_name = response.data.organization_name;
                        this.form.emails = response.data.emails
                    }
                )
            }
            return Promise.resolve();
        },
        saveProvider() {
            if (this.formIsValid()) {
                const saveApi = (
                    this.isEdit ? this.$providerService.patch(this.providerId, {...this.form, deleted_email: this.deletedEmail }) : this.$providerService.post(this.form)
                );
                saveApi.then(
                    () => {
                        this.$emit('providerSaved')
                        this.$dtoast.pop({
                            preset: "success",
                            content: 'Insegna salvata',
                        })
                    }
                ).catch(xhr => {
                    this.$root.$emit('renderError', xhr)
                })
            }
        },
        addEmails() {
            this.form.emails.push(
                {'email': '', 'phone': ''},
            )
        },
        removeEmails(index) {
            const providerEmailId =this.form.emails[index].id
            if (providerEmailId) {
                this.deletedEmail.push(providerEmailId)
            }
            this.form.emails.splice(index, 1)
        }
    }
}
</script>
