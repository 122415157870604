<template>
    <tasks-project ></tasks-project>
</template>

<script>
import TasksProject from "../projects/TasksProject.vue";

export default {
  name: "Tasks",
  components: {
      TasksProject,
  }
}
</script>

<style scoped>

</style>
