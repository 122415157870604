<template>
    <div>
      <template>
          <v-data-table
              v-if="providers"
              :headers="headers"
              :items="providers"
              :items-per-page="-1"
              class="elevation-1 my_class"
              dense
          >
              <template v-slot:top>
                  <v-col>
                      <v-toolbar flat>
                          <v-toolbar-title>Fornitori</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" dark class="mb-2" @click="addProvider">
                              Aggiungi fornitore
                          </v-btn>
                      </v-toolbar>
                  </v-col>
              </template>
              <template #item.actions="{ item }">
                  <v-icon
                      title="Modifica"
                      small class="ml-2" @click="editProvider(item)">
                      mdi-pencil
                  </v-icon>
                  <v-icon
                      small class="ml-2" title="Elimina"
                      @click="deleteProvider(item)">
                      mdi-delete
                  </v-icon>
              </template>
          </v-data-table>
      </template>
      <!-- Modali -->
      <provider-dialog
          v-if="showProviderDialog"
          v-model="showProviderDialog"
          :provider-id="selectedProviderId"
          @providerSaved="onProviderSaved"/>
      <provider-delete-dialog
          v-if="showProviderDeleteDialog"
          v-model="showProviderDeleteDialog"
          :provider-id="providerIdToDelete"
          @confirmed="loadProviders()"/>
    </div>
</template>

<script>
import {format, parseISO} from "date-fns";
import ProviderDeleteDialog from "./ProviderDeleteDialog.vue";
import ProviderDialog from "./ProviderDialog.vue";

export default {
    components: {
        ProviderDeleteDialog,
        ProviderDialog
    },
    data: () => ({
        providers: null,
        showProviderDialog: false,
        selectedProviderId: null,
        providerIdToDelete: false,
        showProviderDeleteDialog: false,
    }),
    computed: {
        headers() {
            return [
                {value: 'name', text: 'Nome'},
                {value: 'organization_name', text: 'Azienda'},
                {value: 'emails[0].email', text: 'Email'},
                {value: 'emails[0].phone', text: 'Telefono'},
                {value: 'send_file_description', text: 'Descrizione invio file'},
                {value: 'actions', text: 'Actions', sortable: false}
            ]
        }
    },
    mounted() {
        this.ensureUserInStore().then(() => {
            if (this.$store.state.user.role === 'MANAGEMENT_CONTROL') {
                this.$router.push({name: 'tasks'});
            }
        })
        this.loadProviders()
    },
    methods: {
        addProvider() {
            this.selectedProviderId = null
            this.showProviderDialog = true
        },
        deleteProvider(item) {
            this.providerIdToDelete = item.id
            this.showProviderDeleteDialog = true
        },
        editProvider(item) {
            this.selectedProviderId = item.id
            this.showProviderDialog = true
        },
        formatDate(isoDate) {
            return isoDate ? format(parseISO(isoDate), 'dd/MM/yyyy') : ''
        },
        loadProviders() {
            this.$providerService.list().then(
                response => {
                    this.providers = response.data;
                }
            )
        },
        onProviderSaved() {
            this.showProviderDialog = false
            this.loadProviders()
        },
        ensureUserInStore() {
            if (!this.$store.state.user) {
                return this.$store.dispatch('setCurrentUser');
            } else {
                return Promise.resolve();
            }
        }
    }
}
</script>
<style>
.my_class td{
  font-size: 12px!important;
  height: 1!important;
}
</style>
