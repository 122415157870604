// Importa Vue per utilizzare Vue.cookies se non sei in un componente Vue
import Vue from 'vue';

export default function authHeader() {
    const userToken = JSON.parse(window.localStorage.getItem('token'));

    // Utilizza Vue.cookies per ottenere il valore del token CSRF
    const csrfToken = Vue.$cookies.get('csrftoken');

    return {
        Authorization: 'Token ' + userToken,
        'X-CSRFToken': csrfToken
    };
}
