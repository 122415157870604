<template>
    <v-card v-if="activity && (product || isCreate)" elevation="2" class="blue-grey lighten-5">
        <v-card-title>
            <span v-if="isCreate">
                Crea prodotto per l'attività <b>{{ activity.name }}</b>
            </span>
            <span v-else>
                Modifica prodotto <b>{{ this.form.name }}</b> per l'attività <b>{{ activity.name }}</b>
            </span>
        </v-card-title>
        <v-card-text>
            <form>
                <v-container>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                :disabled="['PURCHASE_OPERATOR'].includes($store.state.user.role)"
                                v-model="form.name"
                                :error-messages="nameErrors"
                                label="Nome"
                                required
                                @input="$v.form.name.$touch()"
                                @blur="$v.form.name.$touch()"
                                prepend-icon="mdi-rename-box"
                                class="white--text"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                  <v-row>
                    <v-col>
                        <tiptap-vuetify
                            v-model="form.description"
                            :extensions="extensions"
                        />
                    </v-col>
                    <v-col>
                      <v-text-field
                          v-model="form.scheduled_hours"
                          label="Ore Previste"
                          prepend-icon="mdi-bullseye-arrow"
                      ></v-text-field>
                      <if65-file-input
                        :disabled="['PURCHASE_OPERATOR'].includes($store.state.user.role)"
                        :initial-url="form.note_attachment"
                        label="Allegato nota prodotto"
                        @change="onNoteAttachmentFileChange"
                      ></if65-file-input>
                      <if65-autocomplete
                        :disabled="['PURCHASE_OPERATOR'].includes($store.state.user.role)"
                          class="mt-2"
                          v-model="form.operators"
                          label="Operatori Grafici"
                          :api="this.$userService.list"
                          :icon="`mdi-pencil-ruler`"
                          :multiple="true"
                          :clearable="true"/>
                    </v-col>
                    </v-row>

                  <v-row>
                    <v-col cols="6">
                      <if65-autocomplete
                          :disabled="['PURCHASE_OPERATOR'].includes($store.state.user.role)"
                          v-model="form.status"
                          label="Stato"
                          :api="this.$productService.status_choices"
                          :icon="`mdi-list-status`"
                          :required="true"/>
                    </v-col>
                    <v-col cols="6">
                      <if65-date-picker
                          v-model="form.delivery_date"
                          label="Data consegna"/>
                    </v-col>
                  </v-row>
                    <v-row>
                        <v-col cols="6">
                            <if65-autocomplete
                                v-model="form.provider"
                                label="Fornitore"
                                :api="this.$providerService.list"
                                :icon="`mdi-hammer-screwdriver`"
                                :clearable="true"/>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                              v-model="form.order_number"
                              label="Numero d'ordine"
                              prepend-icon="mdi-numeric"
                          ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <if65-tag-selector
                                :disabled="['PURCHASE_OPERATOR'].includes($store.state.user.role)"
                                attach
                                v-if="form.sub_activities_names" v-model="form.sub_activities_names"
                                label="Sotto attività"
                                :items="defaultSubActivities"
                                @intialize="initSubActivities"/>
                        </v-col>
                    </v-row>
                </v-container>

                <v-container>
                    <v-row>
                        <v-col cols="10"></v-col>
                        <v-col cols="1">
                            <v-btn
                              :disabled="$store.state.user.role !== 'ADMIN'"
                              v-if="!isCreate"
                              color="secondary"
                              @click="deleteProduct">Elimina</v-btn>
                        </v-col>
                        <v-col cols="1">
                            <v-btn color="primary" type="submit" @click.prevent="submit">Salva</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </form>
        </v-card-text>
        <step3-sub-activity-check-dialog
            v-model="showSubActivityCheckModal"
            :sub-activities-with-task="subActivitiesWithTasks"
            :show-restore-button="showRestoreButton"
            @canceled="initSubActivities"
            @confirmed="saveProduct"
        />
    </v-card>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import If65Autocomplete from '../widgets/If65Autocomplete.vue'
import If65DatePicker from "../widgets/If65DatePicker.vue";
import If65FileInput from "../widgets/If65FileInput.vue";
import If65TagSelector from "../widgets/If65TagSelector.vue";
import Step3SubActivityCheckDialog from "./Step3SubActivityCheckDialog.vue";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'

export default {
  components: {
    If65Autocomplete,
    If65DatePicker,
    If65FileInput,
    If65TagSelector,
    Step3SubActivityCheckDialog,
    TiptapVuetify
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {required},
    }
  },
  props: {
    productId: {
      required: true
    },
    activityId: {
      required: true
    }
  },
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      activity: null,
      defaultSubActivities: [],
      product: null,
      kindItems: [],
      noteAttachmentFile: null,
      noteAttachmentFileChanged: false,
      form: {
        kind: null,
        name: '',
        provider: null,
        delivery_date: null,
        operators: [],
        sub_activities_names: [],
        status: 'DACONSEGNARE',
        description: '',
        order_number: '',
        scheduled_hours: '',
        note: '',
        note_attachment: null,
      },
      showRestoreButton: false,
      showSubActivityCheckModal: false,
      subActivitiesWithTasks: []
    };
  },
  computed: {
    isCreate() {
      return this.productId === 'NEW';
    },
    nameErrors() {
      const errors = []
      if (!this.$v.form.name.$dirty) return errors
      !this.$v.form.name.required && errors.push('Campo richiesto')
      return errors
    },
    selectedKindName() {
      const kindChoice = this.kindItems.find(item => item.value === this.form.kind);
      return kindChoice ? kindChoice.text : '';
    },
  },
  mounted() {
    this.initDefaultSubActivities();
  },
  watch: {
    activityId: {
      handler() {
        this.activity = null
        if (this.activityId) {
          this.$activityService.get(this.activityId).then(
              resp => this.activity = resp.data
          );
        }
      },
      immediate: true
    },
    productId: {
      handler() {
        this.product = null
        if (this.productId && !this.isCreate) {
          this.$productService.get(this.productId).then(
              resp => this.product = resp.data
          );
        }
      },
      immediate: true
    },
    product: {
      handler() {
        this.initForm();
      }
    },
    'form.kind': {
      handler() {
        this.initDefaultSubActivities();
      }
    },
  },
  methods: {
    checkSubActivities(subActivities) {
      return this.$subActivityService.check(this.productId, subActivities).then(
          response => {
            this.subActivitiesWithTasks = response.data;
          }
      );
    },
    deleteProduct() {
      this.checkSubActivities([]).then(
          () => {
            if (this.subActivitiesWithTasks.length) {
              this.showSubActivityCheckModal = true;
            } else {
              this.$productService.delete(this.productId).then(
                  () => {
                    this.$emit('productChange');
                    this.$dtoast.pop({
                      preset: "success",
                      content: 'Prodotto eliminato',
                    })
                  }
              ).catch(xhr => {
                this.$root.$emit('renderError', xhr)
              });
            }
          }
      )
    },
    initDefaultSubActivities() {
      // this.defaultSubActivities = [];
      this.$productService.default_sub_activities(this.form.kind).then(
          response => this.defaultSubActivities = response.data
      )
    },
    initSubActivities() {
      this.form.sub_activities_names = this.product ? this.product.sub_activities_names : []
    },
    initForm() {
      this.form.name = this.product ? this.product.name : ''
      this.form.delivery_date = this.product ? this.product.delivery_date : null
      this.form.kind = this.product ? this.product.kind : null
      this.form.provider = this.product ? this.product.provider : null
      this.form.operators = this.product ? this.product.operators : []
      this.form.sub_activities_names = this.product ? this.product.sub_activities_names : []
      this.form.status = this.product ? this.product.status : 'DACONSEGNARE'
      this.form.description = this.product ? this.product.description : '',
      this.form.order_number = this.product ? this.product.order_number : ''
      this.form.scheduled_hours = this.product ? this.product.scheduled_hours : ''
      this.form.note = this.product ? this.product.note : ''
      this.form.note_attachment = this.product ? this.product.note_attachment : null
    },
    onNoteAttachmentFileChange(files) {
      this.noteAttachmentFile = files
      this.noteAttachmentFileChanged = true
    },
    saveNoteAttachment(productId) {
      if (this.noteAttachmentFileChanged) {
        return this.$productService.saveNoteAttachment(productId, this.noteAttachmentFile)
      } else {
        return Promise.resolve()
      }
    },
    saveProduct() {
      const data = {
        activity: this.activityId,
        ...this.form
      };
      if (this.isCreate) {
        return this.$productService.post(data);
      } else {
        return this.$productService.patch(this.productId, data);
      }
    },
    submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.checkSubActivities(this.form.sub_activities_names).then(
            () => {
              if (this.subActivitiesWithTasks.length) {
                this.showRestoreButton = true;
                this.showSubActivityCheckModal = true;
              } else {
                this.saveProduct().then(
                    xhr => {
                      this.saveNoteAttachment(xhr.data.id).then(
                          () => {
                            this.$emit('productChange')
                            this.$dtoast.pop({
                              preset: "success",
                              content: 'Prodotto salvato',
                            })
                          }
                      )
                    }
                ).catch(
                    xhr => {
                      this.$dtoast.pop({
                        preset: "error",
                        content: xhr.response.data.non_field_errors.join(', '),
                      })
                    }
                );
              }
            }
        )
      }
    }
  },
}
</script>
